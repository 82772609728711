import { gql } from '@apollo/client';

export const SaveStorefrontMutation = gql`
  mutation saveStorefront($_id: String!, $fields: SaveStorefrontInput) {
    saveStorefront(_id: $_id, fields: $fields) {
      _id
      siteName
      tagline
      description
      longDescription
      logo
      splashLogo
      emailBanner
      landingPageThumbnail
      website
      retailPresence
      categories
      hasPackout
      contactName
      contactPhone
      contactEmail
    }
  }
`;

export const saveMarketplaceSKU = gql`
  mutation saveMarketplaceSKU($_id: String!, $facilityIds: [String]!, $fields: SaveSKUInput) {
    saveMarketplaceSKU(_id: $_id, facilityIds: $facilityIds, fields: $fields) {
      _id
      itemName
      description
      truckType
      casesPerPallet
      galleryImages
      longDescription
      thumbnailImage
      palletWeight
      palletLength
      palletHeight
      isPackout
      leadTime
      tags
    }
  }
`;

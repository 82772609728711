import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import { Theme } from '@spoiler-alert/ui-library';

const styles = {
  statusDot: {
    borderRadius: 5,
    height: 10,
    width: 10,
    margin: [13, 0, 13, 12],
    backgroundColor: Theme.grey50,
  },
  active: {
    backgroundColor: Theme.green,
  },
};

const StatusDot = ({ classes, active }) => <div className={`${classes.statusDot} ${active ? classes.active : ''}`}></div>;

StatusDot.propTypes = {
  classes: PropTypes.object,
  active: PropTypes.bool,
};

export default injectSheet(styles)(StatusDot);

import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Loading, AlertService, Theme, ExcelIcon, Button, DragDrop } from '@spoiler-alert/ui-library';
import SheetUploadService from '../../services/upload-sheet-service';

const allowedFileTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const useStyles = createUseStyles(
  {
    uploadBox: {
      padding: 16,
      border: `1px solid ${Theme.grey30}`,
      backgroundColor: Theme.white,
      marginBottom: 24,
      borderRadius: 4,
    },
    dropAreaBase: {
      height: 118,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 24,
      padding: 24,
      borderRadius: 4,
      border: `dashed 1px ${Theme.teal}`,
    },
    dropArea: {
      extend: 'dropAreaBase',
      backgroundColor: Theme.teal5,
    },
    dropAreaActive: {
      extend: 'dropAreaBase',
      backgroundColor: 'rgba(78, 170, 193, 0.3)',
    },
    actionContainer: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    excelIcon: {
      marginRight: 16,
    },
    uploadButton: {
      marginRight: 8,
    },
    instructions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: 24,
    },
    instructionContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
    },
    instructionText: {
      display: 'flex',
      flexDirection: 'column',
    },
    instructionTitle: {
      fontSize: 16,
      fontWeight: 500,
    },
    uploadInstruction: {
      extend: 'instructionTitle',
      '@media (max-width: 1135px)': {
        marginLeft: 90,
      },
    },
    instructionDetail: {
      fontSize: 14,
    },
    container: {
      height: '100%',
    },
    instructionArea: {
      height: '65%',
      display: 'flex',
      justifyContent: 'left',
    },
  },
  { name: 'UploadSheet' }
);

const errorStrings = {
  INVALID_TYPE: 'Your sheet was not submitted due to an incorrect file type. Sheets must be in excel (XLSX) format.',
  SINGLE_FILE_ONLY: 'You can only upload one file at a time, please take the time to upload each file separately',
};

const BulkInventoryEditor = () => {
  const classes = useStyles();
  const [uploadKey, setUploadKey] = useState(0);
  const [fileInput, setFileInput] = useState();
  const [dragActive, setDragActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const showError = (errorMessage) => {
    AlertService.alert({ type: 'warning', message: <span>{errorMessage}</span>, autoDismiss: false });
  };

  const showSuccess = () => {
    AlertService.alert({ type: 'success', message: <span>Inventory updates processed successfully</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const handleFileChange = (ev) => {
    setDragActive(false);
    const fileList = ev.target.files || ev.dataTransfer.files;
    const files = Array.from(fileList);

    if (!files.every((file) => allowedFileTypes.includes(file.type))) {
      showError(errorStrings.INVALID_TYPE);
    } else {
      SheetUploadService.importSheet(files[0], ev.target.value || files[0].name).then((r) => {
        setLoading(true);
        if (r.success) {
          showSuccess();
        } else {
          showError(r.errorMessage);
        }
        setLoading(false);
        // Rotating this key resets the file input element
        setUploadKey(uploadKey + 1);
      });
    }
  };

  const handleDropError = (errorCode, message) => {
    if (errorStrings[errorCode]) showError(errorStrings[errorCode]);
    else showError(message);
    setDragActive(false);
  };

  const uploadClick = () => {
    fileInput.click();
  };

  return (
    <div className={classes.container}>
      <div className={classes.instructionArea}>
        <div className={classes.instructionContainer}>
          <div className={classes.instructions}>
            <div className={classes.instructionText}>
              <span className={classes.instructionTitle}>
                {' '}
                Make sure your inventory sheet is in Excel (.xlsx) format. The sheet should contain the following columns for each item:
              </span>
              <li className={classes.instructionDetail}>Inventory ID - required</li>
              <li className={classes.instructionDetail}>Unit Cost</li>
              <li className={classes.instructionDetail}>Unit Price</li>
              <li className={classes.instructionDetail}>Unit Net Weight</li>
              <li className={classes.instructionDetail}>Unit Gross Weight</li>
              <li className={classes.instructionDetail}>Uploaded Reserve Price</li>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.uploadBox}>
        <DragDrop
          className={dragActive ? classes.dropAreaActive : classes.dropArea}
          accepts={allowedFileTypes}
          onDrop={handleFileChange}
          onError={handleDropError}
          onDragOver={() => setDragActive(true)}
          onDragLeave={() => setDragActive(false)}
        >
          <div className={classes.actionContainer}>
            {!dragActive && <ExcelIcon className={classes.excelIcon} />}
            {!dragActive && (
              <Button onClick={uploadClick} secondary className={classes.uploadButton}>
                Browse for File
              </Button>
            )}
            {(!dragActive && <span className={classes.uploadInstruction}>or drag & drop your excel sheet here to submit</span>) || (
              <span className={classes.instructionTitle}>Drop your file to submit</span>
            )}
          </div>
        </DragDrop>
        <input
          type="file"
          id="sheet-upload"
          key={`upload-${uploadKey}`}
          onChange={handleFileChange}
          accept={allowedFileTypes.join(',')}
          style={{ display: 'none' }}
          ref={(el) => setFileInput(el)}
        />
      </div>{' '}
      {loading && <Loading loading={loading} size="large" />}
    </div>
  );
};

export default BulkInventoryEditor;

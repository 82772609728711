import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Theme, Flyout, OverlayService, Button } from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { BuyerManagerDetailQuery } from '../../graphql/queries';
import { VTable, VColumn } from '../../components/v-table';
import { SyncWithNUMOCOMutation } from '../../graphql/mutations';

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px solid ${Theme.tableBorderColor}`,
    flex: '1 1 50%',
  },
  pageGrid: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    gap: 12,
  },
  search: {
    width: 386,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
  fieldContainer: {
    gap: 12,
    display: 'grid',
    grid: 'auto-flow / auto auto auto',
  },
  fieldBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    fontSize: 24,
  },
  fieldLabel: {
    fontWeight: 'bold',
  },
  dataUtilitiesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  dataUtility: {
    width: '100%',
    boxSizing: 'border-box',
    padding: [0, 0, 20, 0],
  },
  dataUtilityInner: {
    padding: 15,
    border: `2px ${Theme.grey80} solid`,
    borderRadius: 2,
    height: '100%',
    boxShadow: '0 1px 10px rgba(0,0,0,0.3)',
  },
  items: {
    width: '90%',
  },
});

const BuyerManagerDetail = ({ match, history }) => {
  const classes = useStyles();
  const [synced, setSynced] = useState(false);
  const [showFlyout, setShowFlyout] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [linkCopied, setLinkCopied] = useState(false);
  const { data, loading } = useQuery(BuyerManagerDetailQuery, {
    variables: { buyerSiteId: match.params.buyerSiteId },
    skip: !synced,
  });
  const [syncWithNUMOCO, { loading: syncWithNUMOCOLoading }] = useMutation(SyncWithNUMOCOMutation, {
    variables: { buyerSiteId: match.params.buyerSiteId },
  });

  useEffect(() => {
    TitleService.setTitles('Buyer Manager Detail');
    Breadcrumbs.set([
      {
        url: routePaths.buyerManager,
        title: 'Buyer Manager',
      },
      {
        url: history.location.pathname,
        title: 'Buyer Manager Detail',
      },
    ]);
    async function sync() {
      await syncWithNUMOCO();
      setSynced(true);
    }
    sync();
  }, []);

  const invoiceColumns = useMemo(() => {
    return [
      new VColumn({
        field: 'invoiceNumber',
        displayName: 'Invoice Number',
        sortable: true,
        defaultSort: true,
        defaultSortDirection: 'asc',
        formatter: (value) => value || 'N/A',
      }),
      new VColumn({
        field: 'denomination',
        displayName: 'Denomination',
        sortable: true,
      }),
      new VColumn({
        field: 'totalAmount',
        displayName: 'Total Amount',
        sortable: true,
      }),
      new VColumn({
        field: 'status',
        displayName: 'Status',
        sortable: true,
      }),
      new VColumn({
        field: 'note',
        displayName: 'Note',
        sortable: true,
        formatter: (value) => value || 'N/A',
      }),
      new VColumn({
        field: 'dueDate',
        displayName: 'Due Date',
        sortable: true,
        formatter: (value) => (value && new Date(value).toLocaleDateString()) || 'N/A',
      }),
    ];
  }, [data]);

  const itemColumns = useMemo(() => {
    return [
      new VColumn({
        field: 'description',
        displayName: 'Description',
        sortable: true,
        defaultSort: true,
        defaultSortDirection: 'asc',
        formatter: (value) => value || 'N/A',
      }),
      new VColumn({
        field: 'quantity',
        displayName: 'Quantity',
        sortable: true,
      }),
      new VColumn({
        field: 'unitCost',
        displayName: 'Unit Cost',
        sortable: true,
      }),
      new VColumn({
        field: 'lineTotal',
        displayName: 'Line Total',
        sortable: true,
      }),
    ];
  }, [selectedRow]);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    OverlayService.show();
    setShowFlyout(true);
  };

  const hideFlyout = () => {
    OverlayService.hide();
    setShowFlyout(false);
    setSelectedRow(null);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(data?.buyerManagerDetailQuery?.onboardingURL);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 1500);
  };

  return (
    <div className={classes.pageGrid}>
      <div className={classes.dataUtilitiesGrid}>
        <div className={classes.dataUtility}>
          {!loading && !syncWithNUMOCOLoading && (
            <div className={classes.dataUtilityInner}>
              <h2>{data?.buyerManagerDetailQuery.siteName}</h2>
              <div className={classes.fieldContainer}>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>Type: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.type}</span>
                </div>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>Status: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.status}</span>
                </div>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>KYB Status: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.kybStatus}</span>
                </div>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>Credit Limit: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.creditLimit}</span>
                </div>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>Credit Rating: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.creditRating || 'Not Available'}</span>
                </div>
                <div className={classes.fieldBox}>
                  <span className={classes.fieldLabel}>Credit Verification Status: </span>
                  <span className={classes.field}>{data?.buyerManagerDetailQuery.creditVerificationStatus || 'Not Available'}</span>
                </div>
                <div className={classes.fieldBox}>
                  {(data?.buyerManagerDetailQuery.onboardingURL && (
                    <Button primary onClick={handleCopy}>
                      {(linkCopied && 'Link Copied!') || 'Copy Onboarding Link'}
                    </Button>
                  )) || (
                    <>
                      <span className={classes.fieldLabel}>Onboarding URL: </span>
                      <span className={classes.field}>Not Available</span>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {(data?.buyerManagerDetailQuery?.invoices.length && (
        <div>
          <h2>Invoices</h2>
          <div className={classes.tableWrap}>
            <VTable key={1} data={data?.buyerManagerDetailQuery?.invoices} cols={invoiceColumns} onRowClick={handleRowClick} />
          </div>
        </div>
      )) ||
        null}
      <Flyout position="right" open={showFlyout} onHide={hideFlyout} classes={{ flyout__wrapper: classes.items }}>
        <div>
          <h2>Items</h2>
          <div className={classes.tableWrap}>
            <VTable key={2} data={selectedRow?.items || []} cols={itemColumns} />
          </div>
        </div>
      </Flyout>
    </div>
  );
};

BuyerManagerDetail.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
};

export default BuyerManagerDetail;

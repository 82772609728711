import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Theme, Search, Timing, UserIcon } from '@spoiler-alert/ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { BuyerManagerQuery } from '../../graphql/queries';
import { VTable, VRowAction, VColumn } from '../../components/v-table';
import { SyncWithNUMOCOMutation } from '../../graphql/mutations';
import NUMOCOCompanyModal from './numoco-company-modal';

const useStyles = createUseStyles({
  pageGrid: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 16,
    gap: 12,
  },
  tableWrap: {
    border: `1px solid ${Theme.tableBorderColor}`,
    flex: '1 1 50%',
  },
  search: {
    width: 386,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
});

const BuyerManager = ({ history }) => {
  const classes = useStyles();
  const [synced, setSynced] = useState(false);
  const { data, loading, refetch } = useQuery(BuyerManagerQuery, { skip: !synced });
  const sitesList = data?.buyerManagerQuery.sites || [];
  const [searchText, setSearchText] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [syncWithNUMOCO, { loading: syncWithNUMOCOLoading }] = useMutation(SyncWithNUMOCOMutation);

  useEffect(() => {
    TitleService.setTitles('Buyer Manager');
    Breadcrumbs.set([
      {
        url: routePaths.buyerManager,
        title: 'Buyer Manager',
      },
    ]);
    async function sync() {
      await syncWithNUMOCO();
      setSynced(true);
    }
    sync();
  }, []);

  const siteIdToNameMap = useMemo(() => {
    return sitesList.reduce((map, site) => {
      map.set(site.id, site.name);
      return map;
    }, new Map());
  }, [sitesList]);

  const columns = useMemo(() => {
    return [
      new VColumn({ field: 'siteName', displayName: 'Buyer Name', sortable: true, defaultSort: true, defaultSortDirection: 'asc' }),
      new VColumn({
        field: 'companyId',
        displayName: 'Exists in NUMOCO?',
        sortable: true,
        formatter: (value) => (value && 'Yes') || 'No',
        sortField: (row) => (row.companyId && 1) || -1,
      }),
      new VColumn({
        field: 'creditVerificationStatus',
        displayName: 'Credit Verification Status',
        sortable: true,
        formatter: (value) => value || 'N/A',
      }),
      new VColumn({
        field: 'creditRating',
        displayName: 'Credit Rating',
        sortable: true,
        formatter: (value) => value || 'N/A',
      }),
      new VColumn({
        field: 'creditLimit',
        displayName: 'Credit Limit',
        sortable: true,
        formatter: (value) => value || 'N/A',
      }),
    ];
  }, [siteIdToNameMap]);

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const onRowClick = (row) => {
    if (row.companyId) history.push(`${history.location.pathname}/${row._id}`);
    else {
      setSelectedRow(row);
      setShowInviteModal(true);
    }
  };

  const handleHideModal = async () => {
    setShowInviteModal(false);
    await syncWithNUMOCO();
    await refetch();
  };

  return (
    <div className={classes.pageGrid}>
      <div className={classes.header}>
        <Search onChange={handleSearch} className={classes.search} />
      </div>
      <div className={classes.tableWrap}>
        <VTable key={1} data={sitesList} cols={columns} loading={syncWithNUMOCOLoading || loading} searchText={searchText} onRowClick={onRowClick} />
      </div>
      <NUMOCOCompanyModal open={showInviteModal} handleHideModal={handleHideModal} buyerProfile={selectedRow} />
    </div>
  );
};

BuyerManager.propTypes = {
  history: PropTypes.object,
};

export default BuyerManager;

import { gql } from '@apollo/client';

// Fragments
const COLUMN_FIELDS = gql`
  fragment ColumnFields on Column {
    field
    displayName
    visible
    sortable
    defaultSort
    defaultSortDirection
    formatter
  }
`;

// Queries
export const CUSTOMERS_AND_DATA_TABLE_PROFILES = gql`
  query getSitesAndDataTableProfiles {
    dataTableManagerQuery {
      _id
      allSellerOrgs {
        _id
        siteName
      }
      dataTableProfiles {
        id
        dataTableName
        siteName
        siteId
        dataTableType
      }
      possibleTableTypes
    }
  }
`;

export const DATA_TABLE_PROFILE_FIELDS_QUERY = gql`
  ${COLUMN_FIELDS}
  query getColumnsByDataTableProfileId($dataTableProfileId: String) {
    dataTableManagerQuery {
      _id
      dataTableProfile(dataTableProfileId: $dataTableProfileId) {
        id
        siteName
        siteId
        dataTableName
        dataTableType
        columns {
          ...ColumnFields
        }
        possibleFields {
          name
          type
        }
      }
    }
  }
`;

// Mutations
export const SAVE_DATA_TABLE_PROFILE_MUTATION = gql`
  mutation saveDataTableProfile($dataTableProfile: DataTableProfileInputType) {
    SaveDataTableProfile(dataTableProfile: $dataTableProfile) {
      id
      dataTableName
      siteName
      siteId
      columns {
        field
        displayName
        visible
        sortable
        defaultSort
        defaultSortDirection
        formatter
      }
    }
  }
`;

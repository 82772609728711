import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { SelectOption, PencilIcon, TextInput } from '@spoiler-alert/ui-library';
import MemorySelect from '../../components/forms/memory-select';
import { CUSTOMERS_AND_DATA_TABLE_PROFILES, DATA_TABLE_PROFILE_FIELDS_QUERY } from '../data-table-manager/dtm-queries';
import { AdminOfferByCurrentCycleSiteQuery } from './offer-manager-queries';
import { VTable, VRowAction } from '../../components/v-table';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import OfferEditor from './offer-editor';
import VColumn from '../../components/v-table/v-column';

const useStyles = createUseStyles({
  inventoryManagerWrap: {
    height: 'calc(100vh - 54px)',
  },
  gridWrap: {
    height: 'calc(100% - 52px)',
    width: '100%',
    position: 'relative',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  inputElement: {
    width: 300,
    marginRight: 8,
  },
  searchElement: {
    extend: 'inputElement',
    margin: '0 0 0 auto',
  },
  selectBox: {
    marginRight: 8,
  },
});

const OfferManager = () => {
  const classes = useStyles();
  useEffect(() => {
    TitleService.setTitles('Offer Manager');
    Breadcrumbs.set([
      {
        url: routePaths.offerManager,
        title: 'Offer Manager',
      },
    ]);
  }, []);
  const [siteSelection, setSiteSelection] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [editingOffer, setEditingOffer] = useState(null);
  const { data: sitesAndProfiles, loading: loadingSites } = useQuery(CUSTOMERS_AND_DATA_TABLE_PROFILES);
  const existingDataTableProfiles = sitesAndProfiles?.dataTableManagerQuery?.dataTableProfiles || [];
  const referenceProfile = useMemo(() => {
    if (siteSelection?.length > 0 && existingDataTableProfiles.length > 0) {
      const filteredProfiles = existingDataTableProfiles.filter((p) => p.dataTableName === 'Offer Review Buyer');
      const siteProfile = filteredProfiles.find((fp) => fp.siteId === siteSelection[0].value);
      if (siteProfile) {
        return siteProfile;
      }
      return filteredProfiles.find((fp) => fp.siteName === 'default');
    }
    return null;
  }, [existingDataTableProfiles, siteSelection]);
  const { data: profile, loading: loadingProfile } = useQuery(DATA_TABLE_PROFILE_FIELDS_QUERY, {
    variables: {
      dataTableProfileId: referenceProfile?.id,
    },
    skip: !referenceProfile,
    fetchPolicy: 'no-cache',
  });
  const {
    data: offerResult,
    loading: loadingOffer,
    error: errorLoadingOffers,
  } = useQuery(AdminOfferByCurrentCycleSiteQuery, {
    variables: {
      siteId: siteSelection?.length > 0 ? siteSelection[0].value : undefined,
    },
    skip: !siteSelection?.length > 0,
  });
  const loading = loadingOffer || loadingProfile || loadingSites;
  const sites = sitesAndProfiles?.dataTableManagerQuery?.allSellerOrgs;
  const offers = useMemo(() => {
    return [...(offerResult?.adminOfferByCurrentCycleSiteQuery || [])];
  }, [offerResult]);
  const userSite = useMemo(() => {
    if (!siteSelection || !profile) return null;
    const [siteOption] = siteSelection;
    const { value: siteId, text: siteName } = siteOption;
    const { dataTableName } = referenceProfile;
    return {
      _id: siteId,
      siteName,
      dataTableProfiles: [
        {
          dataTableName,
          siteName,
          siteId,
          columns: profile.dataTableManagerQuery.dataTableProfile.columns,
        },
      ],
    };
  }, [siteSelection, profile]);

  const handleEditAction = (row) => {
    setEditingOffer({ ...row });
  };
  const handleHideModal = () => setEditingOffer(null);
  let noDataMessage = 'No data was found';
  if (loadingOffer) noDataMessage = 'Loading...';
  if (errorLoadingOffers) noDataMessage = errorLoadingOffers.message;
  const columns = useMemo(() => {
    return [
      new VColumn({ field: '_id', displayName: 'ID', sortable: false, defaultSort: true }),
      new VColumn({ field: 'inventoryId', displayName: 'Inventory ID', sortable: false, defaultSort: false }),
      new VColumn({
        field: 'logisticsRelationshipName',
        displayName: 'Destination',
        visible: true,
        sortable: false,
      }),
      new VColumn({
        field: 'quantity',
        displayName: 'Quantity',
        visible: true,
        sortable: false,
      }),
      new VColumn({ field: 'totalPrice', displayName: 'Total Price', sortable: false, defaultSort: false }),
      new VColumn({ field: 'buyerProposedQuantity', displayName: 'Buyer Proposed Quantity', sortable: false, defaultSort: false }),
      new VColumn({ field: 'buyerProposedTotalPrice', displayName: 'Buyer Proposed Total Price', sortable: false, defaultSort: false }),
    ];
  });
  return (
    <div className={classes.inventoryManagerWrap}>
      <div className={classes.controls}>
        <div className={classes.inputElement}>
          <MemorySelect
            cacheId="ManagerSiteSelect"
            search
            placeholderText="Select a Site"
            onChange={(items) => setSiteSelection(items)}
            selectedItems={siteSelection}
          >
            {sites &&
              sites.map((s) => (
                <SelectOption key={s._id} value={s._id} searchText={s.siteName}>
                  {s.siteName}
                </SelectOption>
              ))}
          </MemorySelect>
        </div>
        <TextInput value={searchText} labelText="Search" onChange={(value) => setSearchText(value)} className={classes.searchElement} />
      </div>
      <div className={classes.gridWrap}>
        {userSite && (
          <VTable
            key={userSite._id}
            searchText={searchText}
            data={offers}
            cols={columns}
            noDataMessage={noDataMessage}
            loading={loading}
            profileName="Offer Review Buyer"
            userSite={userSite}
            border
            sticky
            stickyOffset={0}
            rowActions={[
              <VRowAction
                key={0}
                tooltipText="Edit"
                icon={PencilIcon}
                onClick={(row) => handleEditAction.bind(this, row)}
                primary
                disabledRows={[]}
              />,
            ]}
          />
        )}
      </div>
      <OfferEditor offer={editingOffer} setOffer={setEditingOffer} handleHideModal={handleHideModal} />
    </div>
  );
};

export default OfferManager;

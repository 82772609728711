import { gql } from '@apollo/client';

export const createAdminInvite = gql`
  mutation createAdminInvite($firstName: String!, $lastName: String!, $email: String!) {
    createAdminInvite(firstName: $firstName, lastName: $lastName, email: $email) {
      _id
      firstName
      lastName
      email
      expired
      errored
      error
    }
  }
`;

export const EditAdminUser = gql`
  mutation editAdminUser($id: String!, $firstName: String!, $lastName: String!, $email: String!) {
    editAdminUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email) {
      firstName
      lastName
      email
    }
  }
`;

export const BulkRevokeAdminInvite = gql`
  mutation bulkRevokeAdminInvite($inviteIds: [String]!) {
    bulkRevokeAdminInvite(inviteIds: $inviteIds) {
      _id
    }
  }
`;

export const BulkDeleteAdminUser = gql`
  mutation bulkDeleteAdminUser($userIds: [String]!) {
    bulkDeleteAdminUser(userIds: $userIds) {
      _id
    }
  }
`;

export const BulkResendAdminInvite = gql`
  mutation bulkResendAdminInvite($inviteIds: [String]!) {
    bulkResendAdminInvite(inviteIds: $inviteIds) {
      _id
    }
  }
`;

export const CreateSite = gql`
  mutation createSite($site: SiteParamsInput) {
    createSite(site: $site) {
      result {
        _id
        siteName
      }
      errors
    }
  }
`;

export const EditSite = gql`
  mutation editSite($site: EditSiteParamsInput) {
    editSite(site: $site) {
      result {
        _id
        siteName
      }
      errors
    }
  }
`;

export const toggleImpersonateSite = gql`
  mutation toggleImpersonateSite($siteIdToImpersonate: String) {
    toggleImpersonateSite(siteIdToImpersonate: $siteIdToImpersonate) {
      _id
    }
  }
`;

export const endTransactionCycle = gql`
  mutation endTransactionCycle($siteId: String) {
    endTransactionCycle(siteId: $siteId) {
      _id
      transactionCycle {
        _id
        startedAt
        endedAt
        status
      }
    }
  }
`;

export const startTransactionCycle = gql`
  mutation startTransactionCycle($siteId: String) {
    startTransactionCycle(siteId: $siteId) {
      _id
      transactionCycle {
        _id
        startedAt
        endedAt
        status
      }
    }
  }
`;

export const togglePauseTransactionCycle = gql`
  mutation togglePauseTransactionCycle($siteId: String) {
    togglePauseTransactionCycle(siteId: $siteId) {
      _id
      transactionCycle {
        _id
        startedAt
        endedAt
        status
      }
    }
  }
`;

export const undoTransactionCycleReset = gql`
  mutation undoTransactionCycleReset($siteId: String, $revert: Boolean) {
    undoTransactionCycleReset(siteId: $siteId, revert: $revert) {
      _id
      transactionCycle {
        _id
        startedAt
        endedAt
        status
      }
    }
  }
`;

export const UpdateDistributionList = gql`
  mutation updateDistributionList(
    $listName: String
    $listId: String
    $listType: String
    $bsrIds: [String]
    $siteId: String
    $offerEmailTemplateId: String
    $offerEmailCustomContent: String
    $archived: Boolean
  ) {
    updateDistributionList(
      listName: $listName
      listId: $listId
      listType: $listType
      bsrIds: $bsrIds
      siteId: $siteId
      offerEmailTemplateId: $offerEmailTemplateId
      offerEmailCustomContent: $offerEmailCustomContent
      archived: $archived
    ) {
      result {
        _id
      }
      errors
    }
  }
`;

export const UpdateCustomFilter = gql`
  mutation updateCustomFilter($filterName: String!, $filterId: String, $filterSiteId: String!, $filterQuery: String!, $importProfileId: String!) {
    updateCustomFilter(
      filterName: $filterName
      filterId: $filterId
      filterSiteId: $filterSiteId
      filterQuery: $filterQuery
      importProfileId: $importProfileId
    ) {
      _id
    }
  }
`;

export const UpdateDefaultPricingStrategy = gql`
  mutation updateDefaultPricingStrategy($siteId: String!, $defaultPricingStrategy: String!, $defaultPricingPercentage: safeFloat!) {
    updateDefaultPricingStrategy(
      siteId: $siteId
      defaultPricingStrategy: $defaultPricingStrategy
      defaultPricingPercentage: $defaultPricingPercentage
    ) {
      _id
    }
  }
`;

export const UpdateSiteName = gql`
  mutation updateSiteName($siteName: String!, $editedSiteName: String!) {
    updateSiteName(siteName: $siteName, editedSiteName: $editedSiteName) {
      _id
    }
  }
`;

export const UpdateBuyerSellerRelationship = gql`
  mutation updateBuyerSellerRelationship(
    $buyerSiteName: String
    $sellerSiteName: String
    $buyerEmails: [String]
    $sellerEmails: [String]
    $bsrName: String
    $bsrType: String
    $editBsr: Boolean
    $originalBsrName: String
    $archived: Boolean
  ) {
    updateBuyerSellerRelationship(
      buyerSiteName: $buyerSiteName
      sellerSiteName: $sellerSiteName
      buyerEmails: $buyerEmails
      sellerEmails: $sellerEmails
      bsrName: $bsrName
      bsrType: $bsrType
      editBsr: $editBsr
      originalBsrName: $originalBsrName
      archived: $archived
    ) {
      errors
      result
    }
  }
`;

export const AddBuyerSellerRelationship = gql`
  mutation addBuyerSellerRelationship(
    $buyerSiteName: String
    $sellerSiteName: String
    $buyerEmails: [String]
    $sellerEmails: [String]
    $bsrName: String
  ) {
    addBuyerSellerRelationship(
      buyerSiteName: $buyerSiteName
      sellerSiteName: $sellerSiteName
      buyerEmails: $buyerEmails
      sellerEmails: $sellerEmails
      bsrName: $bsrName
    ) {
      errors
      result
    }
  }
`;

export const UpdateSiteAddress = gql`
  mutation updateSiteAddress($siteId: String!, $siteAddress: SiteAddressParamsInput!) {
    updateSiteAddress(siteId: $siteId, siteAddress: $siteAddress) {
      site {
        _id
      }
      errors
    }
  }
`;

export const UpdateSiteTags = gql`
  mutation updateSiteTags($siteId: String!, $tags: [String]!) {
    updateSiteTags(siteId: $siteId, tags: $tags) {
      site {
        _id
      }
      errors
    }
  }
`;

export const UpdateImportEmailRecipients = gql`
  mutation updateImportEmailRecipients($siteId: String!, $importEmailRecipients: [String]!, $emailType: String!) {
    updateImportEmailRecipients(siteId: $siteId, importEmailRecipients: $importEmailRecipients, emailType: $emailType) {
      site {
        _id
      }
      errors
    }
  }
`;

export const RetryExportWithDataExportIds = gql`
  mutation RetryExportWithDataExportIds($dataExportIds: [String], $exportRunId: String!) {
    retryExportWithDataExportIds(dataExportIds: $dataExportIds, exportRunId: $exportRunId) {
      errors
    }
  }
`;

export const UpdateAwardEmailTemplate = gql`
  mutation updateAwardEmailTemplate($siteId: String!, $emailTemplateId: String!) {
    updateAwardEmailTemplate(siteId: $siteId, emailTemplateId: $emailTemplateId) {
      site {
        _id
      }
      errors
    }
  }
`;

export const ToggleTimebombMutation = gql`
  mutation toggleTimebombEnablement($eventId: Int, $enabled: Boolean) {
    toggleTimebombEnablement(eventId: $eventId, enabled: $enabled) {
      setting {
        _id
        eventName
        eventId
        enabled
      }
      timebomb {
        _id
        expires
      }
    }
  }
`;

export const CreateBuyerXToken = gql`
  mutation createBuyerXToken($sellerSiteId: String!, $buyerSiteId: String!, $emailAddress: String!) {
    createBuyerToken(sellerSiteId: $sellerSiteId, buyerSiteId: $buyerSiteId, emailAddress: $emailAddress) {
      token
      pathPrefix
      error
    }
  }
`;

export const SaveReminderMutation = gql`
  mutation saveReminder($action: String!, $fields: SaveReminderInput) {
    saveReminder(action: $action, fields: $fields) {
      reminders {
        _id
        sellerSiteId
        sellerSiteName
        distributionListId
        distributionListName
        offsetValue
        offsetUnit
        enabled
        reminderType
        createdAt
      }
      errors {
        code
        message
      }
    }
  }
`;

export const SaveFeatureFlagMutation = gql`
  mutation saveFeatureFlag($_id: String, $featureName: String, $enabled: Boolean, $siteIds: [String]) {
    saveFeatureFlag(_id: $_id, featureName: $featureName, enabled: $enabled, siteIds: $siteIds) {
      flag {
        _id
        featureName
        enabled
        siteIds
      }
      errors {
        code
        message
      }
    }
  }
`;

export const SaveHolisticsDashboardMutation = gql`
  mutation saveHolisticsDashboard(
    $_id: String
    $name: String
    $dashboardId: Float
    $dashboardType: String
    $defaultForLocation: String
    $sellerSiteIds: [String]
    $drillthroughs: [Float]
    $refreshDatasets: Boolean
  ) {
    saveHolisticsDashboard(
      _id: $_id
      name: $name
      dashboardId: $dashboardId
      dashboardType: $dashboardType
      defaultForLocation: $defaultForLocation
      sellerSiteIds: $sellerSiteIds
      drillthroughs: $drillthroughs
      refreshDatasets: $refreshDatasets
    ) {
      dashboard {
        _id
        name
        reportId
        type
        defaultForLocation
        sellerSiteIds
        orgFilterPaths
        drillthroughs
        createdAt
        updatedAt
      }
      errors {
        code
        message
      }
    }
  }
`;

export const DeleteHolisticsDashboardMutation = gql`
  mutation deleteHolisticsDashboard($_id: String!) {
    deleteHolisticsDashboard(_id: $_id) {
      dashboard {
        _id
      }
    }
  }
`;

export const DeleteNegotiationsMutation = gql`
  mutation deleteNegotiations($offerListingIds: [String]!) {
    deleteNegotiations(offerListingIds: $offerListingIds) {
      _id
      id
      buyerSiteId
      sellerSiteId
      negotiation {
        _id
        id
        status
        externalListingId
      }
    }
  }
`;

export const SyncWithNUMOCOMutation = gql`
  mutation syncWithNUMOCO($buyerSiteId: String) {
    syncWithNUMOCO(buyerSiteId: $buyerSiteId) {
      wasSuccessful
      errors {
        code
        message
      }
    }
  }
`;

export const InviteUsers = gql`
  mutation inviteUsers($users: [NUMOCOUserInput]!, $companyId: String) {
    inviteUsers(users: $users, companyId: $companyId) {
      wasSuccessful
      errors {
        code
        message
      }
    }
  }
`;

export const AddNUMOCOCompany = gql`
  mutation addNUMOCOCompany($siteName: String!, $type: String!, $billingEmail: String!) {
    addNUMOCOCompany(siteName: $siteName, type: $type, billingEmail: $billingEmail) {
      wasSuccessful
      errors {
        code
        message
      }
    }
  }
`;

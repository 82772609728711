import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { SelectOption, PencilIcon, TextInput, Select } from '@spoiler-alert/ui-library';
import MemorySelect from '../../components/forms/memory-select';
import { CUSTOMERS_AND_DATA_TABLE_PROFILES, DATA_TABLE_PROFILE_FIELDS_QUERY } from '../data-table-manager/dtm-queries';
import { AdminInventoryByCurrentCycleSiteQuery } from './inventory-manager-queries';
import { VTable, VRowAction } from '../../components/v-table';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import InventoryEditor from './inventory-editor';

const useStyles = createUseStyles({
  inventoryManagerWrap: {
    height: 'calc(100vh - 54px)',
  },
  gridWrap: {
    height: 'calc(100% - 52px)',
    width: '100%',
    position: 'relative',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  inputElement: {
    width: 300,
    marginRight: 8,
  },
  searchElement: {
    extend: 'inputElement',
    margin: '0 0 0 auto',
  },
  selectBox: {
    marginRight: 8,
  },
});

const InventoryManager = () => {
  const classes = useStyles();

  useEffect(() => {
    TitleService.setTitles('Inventory Manager');
    Breadcrumbs.set([
      {
        url: routePaths.inventoryManager,
        title: 'Inventory Manager',
      },
    ]);
  }, []);

  // const visibleRowsRef = useRef([]);

  const [siteSelection, setSiteSelection] = useState(null);
  const [facilitySelection, setFacilitySelection] = useState(null);
  const [searchText, setSearchText] = useState('');
  // const [checkedRows, setCheckedRows] = useState([]);
  const [editingInventory, setEditingInventory] = useState(null);

  useEffect(() => {
    setFacilitySelection(null);
  }, [siteSelection]);

  const { data: sitesAndProfiles, loading: loadingSites } = useQuery(CUSTOMERS_AND_DATA_TABLE_PROFILES);

  const existingDataTableProfiles = sitesAndProfiles?.dataTableManagerQuery?.dataTableProfiles || [];

  const referenceProfile = useMemo(() => {
    if (siteSelection?.length > 0 && existingDataTableProfiles.length > 0) {
      const filteredProfiles = existingDataTableProfiles.filter((p) => p.dataTableName === 'Inventory');
      const siteProfile = filteredProfiles.find((fp) => fp.siteId === siteSelection[0].value);
      if (siteProfile) {
        return siteProfile;
      }
      return filteredProfiles.find((fp) => fp.siteName === 'default');
    }
    return null;
  }, [existingDataTableProfiles, siteSelection]);

  const { data: profile, loading: loadingProfile } = useQuery(DATA_TABLE_PROFILE_FIELDS_QUERY, {
    variables: {
      dataTableProfileId: referenceProfile?.id,
    },
    skip: !referenceProfile,
    fetchPolicy: 'no-cache',
  });

  const {
    data: inventoryResult,
    loading: loadingInventory,
    error: errorLoadingInventories,
  } = useQuery(AdminInventoryByCurrentCycleSiteQuery, {
    variables: {
      siteId: siteSelection?.length > 0 ? siteSelection[0].value : undefined,
    },
    skip: !siteSelection?.length > 0,
  });

  const loading = loadingInventory || loadingProfile || loadingSites;

  const sites = sitesAndProfiles?.dataTableManagerQuery?.allSellerOrgs;

  const inventories = useMemo(() => {
    return [...(inventoryResult?.adminInventoryByCurrentCycleSiteQuery || [])];
  }, [inventoryResult]);

  const facilities = useMemo(() => {
    const map = inventories.reduce((list, inventory) => {
      list.set(inventory.siteId, { text: inventory.siteName, value: inventory.siteId });
      return list;
    }, new Map());
    return [...map.values()];
  }, [inventories]);

  const filteredInventories = useMemo(() => {
    const siteIds = facilitySelection?.length > 0 ? facilitySelection.map((f) => f.value) : null;
    return inventories.filter((inv) => {
      return siteIds === null || siteIds.includes(inv.siteId);
    });
  }, [facilitySelection, inventories]);

  const userSite = useMemo(() => {
    if (!siteSelection || !profile) return null;
    const [siteOption] = siteSelection;
    const { value: siteId, text: siteName } = siteOption;
    const { dataTableName } = referenceProfile;
    return {
      _id: siteId,
      siteName,
      dataTableProfiles: [
        {
          dataTableName,
          siteName,
          siteId,
          columns: profile.dataTableManagerQuery.dataTableProfile.columns,
        },
      ],
    };
  }, [siteSelection, profile]);

  // const handleRowCheck = ({ value, checked, refresh }) => {
  //   if (refresh) {
  //     setCheckedRows([...checkedRows]);
  //     return;
  //   }
  //   if (checked) {
  //     setCheckedRows([...checkedRows, value]);
  //   } else {
  //     setCheckedRows(checkedRows.filter((checkedId) => checkedId !== value));
  //   }
  // };

  // const handleSelectAll = (result) => {
  //   setCheckedRows(result.checked ? visibleRowsRef.current : []);
  // };

  // const { partial, all } = useMemo(() => {
  //   if (inventories.length === 0) return { partial: false, all: false };
  //   const allSelected = inventories.length === checkedRows.length;
  //   const partialSelection = !allSelected && checkedRows.length > 0;
  //   return { all: allSelected, partial: partialSelection };
  // }, [checkedRows, inventories, searchText]);

  const handleEditAction = (row) => {
    setEditingInventory({ ...row });
  };

  const handleHideModal = () => setEditingInventory(null);

  const tagList = useMemo(() => {
    const userTags = localStorage.getItem('userTags:v1')?.split(',');
    const tagSet = new Set(userTags);
    inventories.forEach((inv) => inv.tags?.forEach((t) => tagSet.add(t)));
    return [...tagSet];
  }, [inventories]);

  let noDataMessage = 'No data was found';
  if (loadingInventory) noDataMessage = 'Loading...';
  if (errorLoadingInventories) noDataMessage = errorLoadingInventories.message;
  return (
    <div className={classes.inventoryManagerWrap}>
      <div className={classes.controls}>
        {/* <MiniTooltip text={`${checkedRows.length}/${inventories.length} item${checkedRows.length === 1 ? '' : 's'} selected`}>
          <SelectBox id="select-all" className={classes.selectBox} partial={partial} all={all} onSelect={handleSelectAll} />
        </MiniTooltip> */}
        <div className={classes.inputElement}>
          <MemorySelect
            cacheId="ManagerSiteSelect"
            search
            placeholderText="Select a Site"
            onChange={(items) => setSiteSelection(items)}
            selectedItems={siteSelection}
          >
            {sites &&
              sites.map((s) => (
                <SelectOption key={s._id} value={s._id} searchText={s.siteName}>
                  {s.siteName}
                </SelectOption>
              ))}
          </MemorySelect>
        </div>
        {facilities.length > 0 && (
          <div className={classes.inputElement}>
            <Select
              search
              multiple
              placeholderText="Filter by Facility"
              onChange={(items) => setFacilitySelection([...items])}
              selectedItems={facilitySelection}
            >
              {facilities.map((s) => (
                <SelectOption key={s.value} value={s.value} searchText={s.text}>
                  {s.text}
                </SelectOption>
              ))}
            </Select>
          </div>
        )}
        <TextInput value={searchText} labelText="Search" onChange={(value) => setSearchText(value)} className={classes.searchElement} />
      </div>
      <div className={classes.gridWrap}>
        {userSite && (
          <VTable
            key={userSite._id}
            searchText={searchText}
            data={filteredInventories}
            noDataMessage={noDataMessage}
            loading={loading}
            profileName="Inventory"
            userSite={userSite}
            // checkboxes
            // checkedRows={checkedRows}
            // onRowCheck={handleRowCheck}
            // visibleRowsRef={visibleRowsRef}
            border
            sticky
            stickyOffset={0}
            rowActions={[
              <VRowAction
                key={0}
                tooltipText="Edit"
                icon={PencilIcon}
                onClick={(row) => handleEditAction.bind(this, row)}
                primary
                disabledRows={[]}
                cypressTag="edit-inventory"
              />,
            ]}
          />
        )}
      </div>
      <InventoryEditor inventory={editingInventory} setInventory={setEditingInventory} handleHideModal={handleHideModal} tagList={tagList} />
    </div>
  );
};

export default InventoryManager;

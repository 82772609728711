import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import { TextInput, Button, AlertService, Card, TypeAhead, Pill, Select, SelectOption, Checkbox } from '@spoiler-alert/ui-library';
import { UpdateBuyerSellerRelationship } from '../../graphql/mutations';
import { SiteRoleTypeAheadQuery, BsrNameTypeAheadQuery } from '../../graphql/queries';
import formStyles from '../../components/forms/form-styles';

const useStyles = createUseStyles({
  ...formStyles,
  'buyer-relationship__submit-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  'seller-relationship__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  'buyer-relationship__container': {
    display: 'flex',
    flexDirection: 'column',
  },
  'add-relationship__wrapper': {
    width: '75%',
    display: 'flex',
  },
  relationship__submit: {
    alignSelf: 'flex-end',
  },
  input__container: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
  },
  card__wrapper: {
    width: '100%',
  },
  checkboxField: {
    width: '50%',
    '&:first-child': {
      marginRight: 24,
    },
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    display: 'block',
    marginLeft: 10,
    cursor: 'pointer',
  },
});

const siteRoles = { BUYER: 'BUYER', SELLER: 'SELLER' };

const EditRelationship = () => {
  const classes = useStyles();
  const [updateBuyerSellerRelationship] = useMutation(UpdateBuyerSellerRelationship);
  const [buyerSiteName, setBuyerSiteName] = useState('');
  const [sellerSiteName, setSellerSiteName] = useState('');
  const [currentBuyerEmail, setCurrentBuyerEmail] = useState('');
  const [currentSellerEmail, setCurrentSellerEmail] = useState('');
  const [buyerEmails, setBuyerEmails] = useState([]);
  const [sellerEmails, setSellerEmails] = useState([]);
  const [allowEmailEntry, setAllowEmailEntry] = useState(false);
  const [bsrName, setBsrName] = useState('');
  const [bsrType, setBsrType] = useState('');
  const [originalBsrName, setOriginalBsrName] = useState('');
  const [mode, setMode] = useState(undefined);
  const [bsrSellerSiteId, setBsrSellerSiteId] = useState('');
  const [archived, setArchived] = useState(false);

  const formatBSRName = (sellerName, buyerName, type) => `${sellerName} - ${buyerName} - ${type}`;

  const updateBuyerSiteName = (_, value) => {
    setBuyerSiteName(value.result.siteName);
    setBsrName(formatBSRName(sellerSiteName, value.result.siteName, bsrType));
  };

  const updateSellerSiteName = (_, value) => {
    setSellerSiteName(value?.result.siteName);
    setBsrSellerSiteId(value?.result._id);
    setBsrName(formatBSRName(value?.result.siteName, buyerSiteName, bsrType));
    if (buyerSiteName) setAllowEmailEntry(true);
  };

  const selectBsrByName = (value) => {
    const { buyerSiteName: buyerName, buyerUserEmails, sellerUserEmails, name, type, archived: isArchived } = value.result;
    setBuyerSiteName(buyerName);
    setBuyerEmails(buyerUserEmails);
    setSellerEmails(sellerUserEmails);
    setOriginalBsrName(name);
    setBsrName(name);
    setBsrType(type);
    setAllowEmailEntry(true);
    setArchived(isArchived);
  };

  const buyerSiteNameTypeahead = () => {
    const params = { siteRole: siteRoles.BUYER };
    return (
      <TypeAhead
        query={SiteRoleTypeAheadQuery}
        queryName="siteRoleTypeAheadQuery"
        dataFormat={[{ displayText: 'buyerSiteName' }]}
        labelText={'Buyer Site'}
        value={buyerSiteName}
        onChange={updateBuyerSiteName.bind(this, 'buyerSiteName')}
        itemLimit={200}
        queryVariables={params}
        autoWidth
      />
    );
  };

  const sellerSiteNameTypeahead = () => {
    const params = { siteRole: siteRoles.SELLER };
    return (
      <TypeAhead
        query={SiteRoleTypeAheadQuery}
        queryName="siteRoleTypeAheadQuery"
        dataFormat={[{ displayText: 'sellerSiteName' }]}
        labelText={'Seller Site'}
        onChange={updateSellerSiteName.bind(this, 'sellerSiteName')}
        value={sellerSiteName}
        itemLimit={200}
        queryVariables={params}
        autoWidth
      />
    );
  };

  const onRemoveBuyerEmail = (email) => {
    setBuyerEmails(buyerEmails.filter((e) => e !== email));
  };

  const onRemoveSellerEmail = (email) => {
    setSellerEmails(sellerEmails.filter((e) => e !== email));
  };

  const addBuyerEmail = () => {
    setBuyerEmails([...buyerEmails, currentBuyerEmail]);
    setCurrentBuyerEmail('');
  };

  const addSellerEmail = () => {
    setSellerEmails([...sellerEmails, currentSellerEmail]);
    setCurrentSellerEmail('');
  };

  const listBuyerPills = () => {
    return buyerEmails.map((be) => <Pill key={`.pill-${be}`} fileName={be} onCancel={onRemoveBuyerEmail} />);
  };

  const listSellerPills = () => {
    return sellerEmails.map((be) => <Pill key={`.pill-${be}`} fileName={be} onCancel={onRemoveSellerEmail} />);
  };

  const resetData = () => {
    setBuyerSiteName('');
    setSellerSiteName('');
    setBsrSellerSiteId('');
    setCurrentBuyerEmail('');
    setCurrentSellerEmail('');
    setBuyerEmails([]);
    setSellerEmails([]);
    setAllowEmailEntry(false);
    setMode(undefined);
    setBsrName('');
    setBsrType('');
  };

  const saveBuyerSellerRelationship = () => {
    updateBuyerSellerRelationship({
      variables: { buyerSiteName, sellerSiteName, buyerEmails, sellerEmails, bsrName, bsrType, editBsr: mode === 'edit', originalBsrName, archived },
    }).then((res) => {
      AlertService.alert({
        type: 'success',
        message: res.data.updateBuyerSellerRelationship.result,
        autoDismiss: true,
        dismissDelay: 5000,
      });
    });
  };

  const bsrNameTypeAhead = () => {
    const additionalQueryParams = { sellerSiteId: bsrSellerSiteId, mode: mode === 'edit' ? 'edit' : 'add' };
    return (
      <TypeAhead
        query={BsrNameTypeAheadQuery}
        queryName="bsrNameTypeAheadQuery"
        labelText={'Buyer Seller Relationship Name'}
        onChange={selectBsrByName}
        itemLimit={10}
        value={originalBsrName}
        queryVariables={additionalQueryParams}
        autoWidth
      />
    );
  };

  const generateBuyerSellerRelationshipListTypes = () => {
    const buyerSellerRelationshipTypes = ['OFFER', 'AWARD', 'SEED'];
    return buyerSellerRelationshipTypes.map((type) => (
      <SelectOption value={type} key={type}>
        {type}
      </SelectOption>
    ));
  };

  const selectedItem = { value: bsrType, text: bsrType };
  const titleText = mode === 'add' ? 'Add Buyer-Seller Relationship' : 'Edit Buyer-Seller Relationship';
  return (
    <div>
      <div>
        <Button className={classes.relationship__submit} onClick={() => setMode('add')} id="reset-buyer-relationship-button">
          Add BSR
        </Button>
        <Button className={classes.relationship__submit} onClick={() => setMode('edit')} id="buyer-relationship-button">
          Edit BSR
        </Button>
      </div>
      {mode && (
        <div id="add-relationship-wrapper" className={classes['add-relationship__wrapper']}>
          <Card className={classes.card__wrapper}>
            <h3>{titleText}</h3>
            {mode === 'edit' && (
              <div>
                {sellerSiteNameTypeahead()}
                {bsrSellerSiteId && bsrNameTypeAhead()}
              </div>
            )}
            {(mode === 'add' || originalBsrName) && (
              <div className={classes['buyer-relationship__container']}>
                <div>{buyerSiteNameTypeahead()}</div>
                <div>{sellerSiteNameTypeahead()}</div>
              </div>
            )}
            {allowEmailEntry ? (
              <div>
                <div className={classes['seller-relationship__container']}>
                  <div>
                    <div className={classes.input__container}>
                      <TextInput
                        inputClassName={classes.relationship__input}
                        onChange={setBsrName}
                        id="bsr-name"
                        required
                        type="text"
                        labelText="Updated Name"
                        value={bsrName}
                      />
                      <div className={classes.checkboxField}>
                        <Checkbox value="archived" checked={archived} onChecked={({ checked }) => setArchived(checked)} />
                        <span className={classes.checkboxLabel} onClick={() => setArchived(!archived)}>
                          Archived
                        </span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className={classes.input__container}>
                      <Select
                        label="BSR Type"
                        id="bsr-type"
                        inputClassName={classes.relationship__input}
                        onChange={(bsr) => {
                          setBsrType(bsr[0].value);
                          setBsrName(formatBSRName(sellerSiteName, buyerSiteName, bsr[0].value));
                        }}
                        required
                        selectedItem={selectedItem}
                      >
                        {generateBuyerSellerRelationshipListTypes()}
                      </Select>
                    </div>
                  </div>
                  <div>
                    {listBuyerPills()}
                    <div className={classes.input__container}>
                      <TextInput
                        inputClassName={classes.relationship__input}
                        onChange={setCurrentBuyerEmail}
                        id="buyer-email"
                        required
                        type="email"
                        labelText="Buyer emails"
                        value={currentBuyerEmail}
                      />
                      <Button type="button" onClick={addBuyerEmail}>
                        Add
                      </Button>
                    </div>
                  </div>
                  <div>
                    {listSellerPills()}
                    <div className={classes.input__container}>
                      <TextInput
                        inputClassName={classes.relationship__input}
                        onChange={setCurrentSellerEmail}
                        id="seller-email"
                        required
                        type="email"
                        labelText="Seller emails"
                        value={currentSellerEmail}
                      />
                      <Button type="button" onClick={addSellerEmail}>
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
                <div className={classes['buyer-relationship__submit-container']}>
                  <Button
                    className={classes.relationship__submit}
                    onClick={resetData}
                    id="reset-buyer-relationship-button"
                    type="submit"
                    loadingText="Loading..."
                  >
                    Reset Form
                  </Button>
                  <Button
                    className={classes.relationship__submit}
                    onClick={saveBuyerSellerRelationship}
                    id="buyer-relationship-button"
                    type="submit"
                    loadingText="Loading..."
                  >
                    Save Relationship
                  </Button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Card>
        </div>
      )}
    </div>
  );
};
EditRelationship.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  serialize: PropTypes.func,
  mutate: PropTypes.func,
  updateBuyerSellerRelationship: PropTypes.func,
};
export default EditRelationship;

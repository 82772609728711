import { gql } from '@apollo/client';

export const SaveOffersMutation = gql`
  mutation saveOffers($offers: [SaveOffersInput]) {
    saveOffers(offers: $offers) {
      offers {
        _id
        buyerProposedQuantity
        buyerProposedTotalPrice
        inventoryId
        logisticsRelationshipName
        quantity
        totalPrice
      }
      errors {
        code
        message
      }
    }
  }
`;

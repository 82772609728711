import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent, ModalFooter, Button, OverlayService } from '@spoiler-alert/ui-library';

const ConfirmationModal = ({ setConfirmUpload, confirmUpload, fileInput }) => {
  const hideModal = () => {
    setConfirmUpload(false);
    OverlayService.hide();
  };
  const confirm = () => {
    hideModal();
    fileInput.click();
  };

  return (
    <Modal onHide={hideModal} open={confirmUpload} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <h2>Heads up!</h2>
        <div>
          Uploading a new profile will overwrite your current one. We recommend downloading a copy of your existing profile first, just in case you
          need to revert any changes later.
          <div>Are you sure you want to proceed?</div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={hideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={false} primary loading={false} onClick={confirm}>
          Yes, I&apos;m sure
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
  setConfirmUpload: PropTypes.func,
  confirmUpload: PropTypes.bool,
  fileInput: PropTypes.object,
};

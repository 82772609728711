import { Theme as theme } from '@spoiler-alert/ui-library';

export default {
  fieldLabel: {
    fontWeight: 'bold',
    display: 'block',
  },
  box: {
    border: `1px ${theme.grey30} solid`,
    padding: 5,
    marginBottom: 10,
  },
  imageBox: {
    extend: 'box',
  },
  fieldBox: {
    extend: 'box',
  },
  textAreaBox: {
    extend: 'box',
    paddingBottom: 20,
  },
  textAreaField: {
    marginBottom: 20,
  },
  phoneField: {
    marginBottom: 20,
  },
  submitBox: {
    extend: 'box',
    display: 'flex',
    flexDirection: 'row-reverse',
    '&>button': {
      marginLeft: 5,
    },
  },
  pills: {
    display: 'flex',
    flexFlow: 'row wrap',
    position: 'relative',
  },
  noImage: {
    height: 100,
    width: 100,
    borderRadius: 4,
    border: `solid 1px ${theme.grey30}`,
    backgroundColor: theme.grey5,
    fill: theme.grey50,
  },
  tagForm: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    '&>table': {
      flexGrow: 1,
      marginLeft: 10,
      borderCollapse: 'collapse',
      border: '1px #000 solid',
      borderRight: 'none',
      textAlign: 'left',
      '& td, & th': {
        borderRight: '1px #000 solid',
      },
    },
  },
  tagBox: {
    display: 'flex',
    flexFlow: 'row wrap',
    minHeight: 60,
    background: theme.grey10,
    padding: 10,
    paddingTop: 2,
    marginBottom: 10,
  },
  extraTagList: {
    extend: 'tagBox',
    '&>div>div': {
      padding: [6, 8],
      height: 'auto',
      '&>span': {
        fontSize: '.8rem',
      },
      '&>div': {
        display: 'none',
      },
    },
  },
  tagField: {
    flex: '1 0 200px',
    marginRight: 10,
  },
};

import React, { useState, useRef, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useQuery, useMutation } from '@apollo/client';
import { Button, AlertService, Select, SelectOption, DownloadIcon, ExportIcon } from '@spoiler-alert/ui-library';

import { UpdateExcludedValues } from './exclusion-mutations';
import { AllSellerSiteNamesAndIdsQuery, ExcludedValuesQuery } from './exclusion-queries';
import EXCLUSION_TYPES from './exclusion-type-enum';

const useStyles = createUseStyles({
  inputHidden: {
    display: 'none',
  },
  select: {
    maxWidth: 300,
  },
  siteSelectOption: {
    paddingRight: '10px !important',
  },
  siteListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  buttonBar: {
    display: 'flex',
    alignItems: 'center',
  },
  primaryButton: {
    marginRight: 10,
  },
});

const UploadExclusionsWidget = () => {
  const classes = useStyles();
  const fileInput = useRef();
  const [selectedSite, setSelectedSite] = useState();
  const [selectedExclusionType, setSelectedExclusionType] = useState();

  const { loading: sitesLoading, data: sitesData } = useQuery(AllSellerSiteNamesAndIdsQuery);

  const sites = useMemo(() => {
    return sitesData?.allSellerSiteNamesAndIdsQuery.map((site) => ({ value: site._id, text: site.siteName }));
  }, [sitesData]);

  const exclusionTypeOptions = useMemo(() => {
    return Object.entries(EXCLUSION_TYPES).map((type) => ({ value: type[1], text: type[1] }));
  }, [EXCLUSION_TYPES]);

  const { loading: loadingCurrentExcludedValues, data: currentExcludedValues } = useQuery(ExcludedValuesQuery, {
    variables: {
      siteId: selectedSite?.value,
      exclusionType: selectedExclusionType?.value,
    },
    skip: !selectedSite || !selectedExclusionType,
  });
  const [updateExcludedValues, { loading: loadingUpdateExcludedValues }] = useMutation(UpdateExcludedValues);

  const handleSelectSite = (e) => {
    setSelectedSite(e[0]);
  };

  const handleSelectExclusionType = (e) => {
    setSelectedExclusionType(e[0]);
  };

  const handleUpdateExcludedValuesMutation = (siteId, excludedValues) => {
    updateExcludedValues({
      variables: { siteId: selectedSite.value, exclusionType: selectedExclusionType.value, excludedValues },
    })
      .then(() => {
        fileInput.current.value = null;
        AlertService.alert({
          type: 'success',
          message: <span>{`${selectedSite.siteName} ${selectedExclusionType?.value} exclusions have successfully been updated!`}</span>,
          autoDismiss: true,
          dismissDelay: 3000,
        });
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>Something went wrong with the exclusion update.</span> }));
  };

  const readFile = (file) => {
    const reader = new FileReader();
    // Listener for the finished loading event
    reader.onloadend = () => {
      const newItemExlcusionDataAsArray = reader.result.split(/\r?\n/).map((row) => {
        return row.split(',')[0];
      });
      handleUpdateExcludedValuesMutation(selectedSite._id, newItemExlcusionDataAsArray);
    };
    // Listener for errors
    reader.onerror = (err) => {
      AlertService.alert({
        type: 'warning',
        autoDismiss: true,
        message: <span>{`Something went wrong with reading the file.  Err: ${err.message}`}</span>,
      });
    };
    // Kicks off the file read
    reader.readAsText(file);
  };

  const handleBrowseFilesClick = (ev) => {
    ev.preventDefault();
    fileInput.current.click();
  };

  const handleFileChange = (ev) => {
    readFile(ev.target.files[0]);
  };

  const handleDownloadCurrentFile = () => {
    const formattedData = currentExcludedValues.excludedValuesQuery.excludedValues.map((dataPoint, index) => {
      if (index === 1) {
        return dataPoint;
      }
      return `\n${dataPoint}`;
    });

    const filename = `${selectedSite.text}_${selectedExclusionType.text}_exclusions`;

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8${formattedData}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    link.click();
  };

  return (
    <div>
      <h4>Upload Exclusions</h4>
      <p>(CSV format, no headers)</p>
      <div className={classes.select}>
        <Select
          minimal
          placeholderText={sitesLoading ? 'Loading Sites...' : 'Select a Site'}
          onChange={handleSelectSite}
          selectedItem={selectedSite}
          className={classes.select}
          search
          multiple={false}
        >
          {sitesLoading
            ? []
            : sites.map((s) => (
                <SelectOption key={s.value} value={s.value} className={classes.siteSelectOption}>
                  {s.text}
                </SelectOption>
              ))}
        </Select>
        <Select
          minimal
          placeholderText="Select an Exclusion Type"
          onChange={handleSelectExclusionType}
          selectedItem={selectedExclusionType}
          className={classes.select}
          multiple={false}
        >
          {EXCLUSION_TYPES &&
            exclusionTypeOptions.map((type) => (
              <SelectOption key={type.value} value={type.value} className={classes.siteSelectOption}>
                {type.text}
              </SelectOption>
            ))}
        </Select>
      </div>
      <div className={classes.buttonBar}>
        <Button
          className={classes.primaryButton}
          icon={ExportIcon}
          onClick={handleBrowseFilesClick}
          loading={loadingUpdateExcludedValues}
          loadingText="Uploading..."
          style={{ marginBottom: 8, marginTop: 8 }}
          disabled={!selectedSite || !selectedExclusionType}
        >
          Upload Exclusions File
        </Button>
        <span className={classes.inputHidden}>
          <input type="file" ref={fileInput} onChange={handleFileChange} />
        </span>
        <Button
          secondary
          icon={DownloadIcon}
          onClick={handleDownloadCurrentFile}
          loading={loadingCurrentExcludedValues}
          disabled={!currentExcludedValues?.excludedValuesQuery || !selectedSite || !selectedExclusionType}
        >
          Download Current Exclusions
        </Button>
      </div>
    </div>
  );
};

export default UploadExclusionsWidget;

import { gql } from '@apollo/client';

export const AdminNegotiationsByCurrentCycleSiteQuery = gql`
  query adminNegotiationByCurrentCycleSiteQuery($siteId: String!) {
    adminNegotiationByCurrentCycleSiteQuery(siteId: $siteId) {
      _id
      id
      buyerSiteId
      sellerSiteId
      negotiation {
        _id
        id
        status
        externalListingId
      }
    }
  }
`;

import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { SelectOption, TextInput, SelectBox, MiniTooltip, Button, Modal, ModalContent, ModalFooter, AlertService } from '@spoiler-alert/ui-library';
import MemorySelect from '../../components/forms/memory-select';
import { AdminNegotiationsByCurrentCycleSiteQuery } from './negotiation-manager-queries';
import { VTable } from '../../components/v-table';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import routePaths from '../../route-paths';
import { AllSellerSiteNamesAndIdsQuery } from '../data-utilities/exclusion-queries';
import { DeleteNegotiationsMutation } from '../../graphql/mutations';

const useStyles = createUseStyles({
  negotiationsManagerWrap: {
    height: 'calc(100vh - 54px)',
  },
  gridWrap: {
    height: 'calc(100% - 52px)',
    width: '100%',
    position: 'relative',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
  },
  inputElement: {
    width: 300,
    marginRight: 8,
  },
  deleteNegotiationsButton: {
    marginRight: 16,
    marginLeft: 8,
  },
  searchElement: {
    extend: 'inputElement',
    margin: '0 0 0 auto',
  },
  selectBox: {
    marginRight: 8,
    marginLeft: 8,
  },
});

const NegotiationManager = () => {
  const classes = useStyles();

  useEffect(() => {
    TitleService.setTitles('Negotiation Manager');
    Breadcrumbs.set([
      {
        url: routePaths.negotiationManager,
        title: 'Negotiation Manager',
      },
    ]);
  }, []);

  const [siteSelection, setSiteSelection] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [checkedRows, setCheckedRows] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteInventory, { loading: deletingNegotiations }] = useMutation(DeleteNegotiationsMutation);

  const handleDelete = () => {
    const variables = {
      offerListingIds: checkedRows.map((id) => id),
    };
    deleteInventory({ variables })
      .then((response) => {
        if (response.data.deleteNegotiations.errors?.length > 0) {
          throw new Error(response.data.deleteNegotiations.errors[0].message);
        }
        setCheckedRows([]);
        setDeleteModalOpen(false);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };

  const visibleRowsRef = useRef([]);

  const { data: sitesData, loading: loadingSites } = useQuery(AllSellerSiteNamesAndIdsQuery);

  const {
    data: negotiationResult,
    loading: loadingNegotiations,
    error: errorLoadingNegotiations,
  } = useQuery(AdminNegotiationsByCurrentCycleSiteQuery, {
    variables: {
      siteId: siteSelection?.length > 0 ? siteSelection[0].value : undefined,
    },
    skip: !siteSelection?.length > 0,
  });

  const loading = loadingNegotiations || loadingSites;

  const sites = sitesData?.allSellerSiteNamesAndIdsQuery;

  const negotiations = useMemo(() => {
    return [...(negotiationResult?.adminNegotiationByCurrentCycleSiteQuery || [])];
  }, [negotiationResult]);

  const filteredNegotiations = useMemo(() => {
    return negotiations.filter((negotiation) => {
      return negotiation.sellerSiteId === siteSelection[0].value && negotiation.negotiation;
    });
  }, [siteSelection, negotiations]);

  const columns = [
    {
      field: 'id',
      displayName: 'OfferListing Id',
      visible: true,
      defaultSort: true,
    },
    {
      field: 'negotiation_id',
      displayName: 'Negotiation Id',
      visible: true,
      formatter: 'negotiationObject|get',
    },
    {
      field: 'negotiation_status',
      displayName: 'Status',
      visible: true,
      formatter: 'negotiationObject|get',
    },
    {
      field: 'negotiation_externalListingId',
      displayName: 'External Listing Id',
      visible: true,
      formatter: 'negotiationObject|get',
    },
    {
      field: 'buyerSiteId',
      displayName: 'Buyer Site Id',
      visible: true,
    },
  ];

  const userSite = useMemo(() => {
    if (!siteSelection) return null;
    const [siteOption] = siteSelection;
    const { value: siteId, text: siteName } = siteOption;
    return {
      _id: siteId,
      siteName,
      dataTableProfiles: [
        {
          dataTableName: 'Negotiation Details',
          siteName,
          siteId,
          columns,
        },
      ],
    };
  }, [siteSelection]);

  const handleRowCheck = ({ value, checked, refresh }) => {
    if (refresh) {
      setCheckedRows([...checkedRows]);
      return;
    }
    if (checked) {
      setCheckedRows([...checkedRows, value]);
    } else {
      setCheckedRows(checkedRows.filter((checkedId) => checkedId !== value));
    }
  };

  const handleSelectAll = (result) => {
    setCheckedRows(result.checked ? visibleRowsRef.current : []);
  };

  const { partial, all } = useMemo(() => {
    if (negotiations.length === 0) return { partial: false, all: false };
    const allSelected = negotiations.length === checkedRows.length;
    const partialSelection = !allSelected && checkedRows.length > 0;
    return { all: allSelected, partial: partialSelection };
  }, [checkedRows, negotiations, searchText]);

  const openDeleteModal = () => setDeleteModalOpen(true);

  const closeDeleteModal = () => setDeleteModalOpen(false);

  let noDataMessage = 'No data was found';
  if (loadingNegotiations) noDataMessage = 'Loading...';
  if (errorLoadingNegotiations) noDataMessage = errorLoadingNegotiations.message;

  return (
    <div className={classes.negotiationsManagerWrap}>
      <div className={classes.controls}>
        <MiniTooltip text={`${checkedRows.length}/${negotiations.length} item${checkedRows.length === 1 ? '' : 's'} selected`}>
          <SelectBox id="select-all" className={classes.selectBox} partial={partial} all={all} onSelect={handleSelectAll} />
        </MiniTooltip>
        <Button
          className={classes.deleteNegotiationsButton}
          onClick={openDeleteModal}
          disabled={!checkedRows.length}
          loading={loadingNegotiations}
          loadingText="Loading Negoiations..."
        >
          Delete Negotiations
        </Button>
        <div className={classes.inputElement}>
          <MemorySelect
            cacheId="ManagerSiteSelect"
            search
            placeholderText="Select a Site"
            onChange={(items) => setSiteSelection(items)}
            selectedItems={siteSelection}
          >
            {sites &&
              sites.map((s) => (
                <SelectOption key={s._id} value={s._id} searchText={s.siteName}>
                  {s.siteName}
                </SelectOption>
              ))}
          </MemorySelect>
        </div>
        <TextInput value={searchText} labelText="Search" onChange={(value) => setSearchText(value)} className={classes.searchElement} />
      </div>
      <div className={classes.gridWrap}>
        {userSite && (
          <VTable
            key={userSite._id}
            searchText={searchText}
            data={filteredNegotiations}
            noDataMessage={noDataMessage}
            loading={loading}
            profileName="Negotiation Details"
            userSite={userSite}
            checkboxes
            checkedRows={checkedRows}
            onRowCheck={handleRowCheck}
            visibleRowsRef={visibleRowsRef}
            border
            sticky
            stickyOffset={0}
          />
        )}
      </div>
      <Modal onHide={closeDeleteModal} open={deleteModalOpen} closeOnEsc closeOnOutsideClick>
        <ModalContent>{`Are you sure you want to delete these ${checkedRows.length} negotiations?`}</ModalContent>
        <ModalFooter>
          <Button type="button" onClick={closeDeleteModal} link>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={deletingNegotiations}
            primary
            loading={deletingNegotiations}
            loadingText="Deleting..."
            onClick={handleDelete}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default NegotiationManager;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { Button, AlertService, Theme as theme } from '@spoiler-alert/ui-library';
import { startTransactionCycle, endTransactionCycle, togglePauseTransactionCycle, undoTransactionCycleReset } from '../../graphql/mutations';

const useStyles = createUseStyles({
  cycle_wrap: {
    display: 'flex',
    alignItems: 'center',
    margin: [-8, 0, -12, 0],
  },
  cycle_info: {
    display: 'flex',
    flexDirection: 'column',
  },
  cycle_description: {
    margin: [0, 10],
  },
  cycle_restore: {
    margin: [0, 10],
    cursor: 'pointer',
    color: theme.infoColor,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  confirm_button: {
    marginRight: 10,
  },
});

const TransactionCycle = ({ site }) => {
  const classes = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);
  const [action, setAction] = useState('');

  const [startTransactionCycleMutation, { loading: loadingStartTransactionCycle }] = useMutation(startTransactionCycle);
  const [endTransactionCycleMutation, { loading: loadingEndTransactionCycle }] = useMutation(endTransactionCycle);
  const [undoTransactionCycleResetMutation, { loading: loadingUndoReset }] = useMutation(undoTransactionCycleReset);
  const [togglePauseTransactionCycleMutation] = useMutation(togglePauseTransactionCycle);

  const mutationOptions = {
    end: endTransactionCycleMutation,
    start: startTransactionCycleMutation,
    restore: undoTransactionCycleResetMutation,
    revert: undoTransactionCycleResetMutation,
  };

  const getAlertString = () => {
    if (action === 'start') {
      return 'start';
    }
    if (action === 'end') {
      return 'end';
    }
    if (action === 'restore') {
      return 'restor';
    }
    if (action === 'revert') {
      return 'revert';
    }
    return '';
  };

  const confirm = () => {
    const mutation = mutationOptions[action];
    const variables = { siteId: site._id };
    if (action === 'revert') {
      variables.revert = true;
    }
    mutation({ variables })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: `Transaction cycle has been successfully ${getAlertString()}ed!`,
          autoDismiss: true,
          dismissDelay: 5000,
        });
        setShowConfirm(false);
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message: `There was a problem ${action}ing the transaction cycle for ${site.siteName}.`,
        });
      });
  };

  const togglePauseCycle = () => {
    togglePauseTransactionCycleMutation({ variables: { siteId: site._id } })
      .then(() => {
        AlertService.alert({
          type: 'success',
          message: `Transaction cycle has been successfully ${site.transactionCycle.status === 'ACTIVE' ? 'paused' : 'resumed'}!`,
          autoDismiss: true,
          dismissDelay: 5000,
        });
        setShowConfirm(false);
      })
      .catch(() =>
        AlertService.alert({
          type: 'warning',
          message: `There was a problem ${site.transactionCycle.status === 'ACTIVE' ? 'paused' : 'resumed'} the transaction cycle for ${
            site.siteName
          }.`,
        })
      );
  };

  const showConfirmationState = (actionString) => {
    setAction(actionString);
    setShowConfirm(true);
  };

  const cancel = () => setShowConfirm(false);
  return (
    <div className={classes.cycle_wrap}>
      {showConfirm ? (
        <Fragment>
          <span className={classes.cycle_description}>Are you sure?</span>
          <Button
            inline
            className={classes.confirm_button}
            onClick={confirm}
            disabled={loadingStartTransactionCycle || loadingEndTransactionCycle || loadingUndoReset}
          >
            Yes, I&rsquo;m Sure
          </Button>
          <Button onClick={cancel} inline secondary>
            Never mind
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          {site.transactionCycle ? (
            <Fragment>
              <Button inline onClick={() => showConfirmationState('end')} className={classes.confirm_button}>
                End Cycle
              </Button>
              <Button inline secondary onClick={togglePauseCycle.bind(this, site)} className={classes.confirm_button}>
                {site.transactionCycle.status === 'ACTIVE' ? 'Pause' : 'Resume'}
              </Button>
              <div className={classes.cycle_info}>
                <span className={classes.cycle_description}>
                  Cycle started: {moment(site.transactionCycle.startedAt).format('MMM D, Y')}
                  {site.transactionCycle.status === 'PAUSED' && ' (Paused)'}
                </span>
                <span className={classes.cycle_restore} onClick={() => showConfirmationState('revert')}>
                  Undo the cycle start and restore the previous cycle
                </span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <Button inline onClick={() => showConfirmationState('start')}>
                Start Cycle
              </Button>
              <div className={classes.cycle_info}>
                <span className={classes.cycle_description}>No active cycle</span>
                <span className={classes.cycle_restore} onClick={() => showConfirmationState('restore')}>
                  Restore previous cycle
                </span>
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

TransactionCycle.propTypes = {
  site: PropTypes.object.isRequired,
};

export default TransactionCycle;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import { createUseStyles } from 'react-jss';
import { TextInput, Button, AlertService, Select, SelectOption, Pill, Theme as theme, Checkbox } from '@spoiler-alert/ui-library';
import {
  EditSite,
  UpdateDefaultPricingStrategy,
  UpdateSiteName,
  UpdateSiteAddress,
  UpdateImportEmailRecipients,
  UpdateAwardEmailTemplate,
  UpdateSiteTags,
} from '../../graphql/mutations';
import formStyles from '../../components/forms/form-styles';
import { listOfStates, pricingStrategyAnchor, siteTags } from '../../utilities';

const useStyles = createUseStyles({
  ...formStyles,
  button__prev: {
    marginRight: 'auto',
  },
  rowInputContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  address: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  genericInput: {
    width: '30%',
    marginRight: 10,
  },
  dataUtilitiesGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  dataUtility: {
    width: '50%',
    minWidth: 500,
    boxSizing: 'border-box',
    padding: [0, 20, 20, 0],
  },
  dataUtilityInner: {
    padding: 15,
    border: `2px ${theme.grey80} solid`,
    borderRadius: 2,
    height: '100%',
    boxShadow: '0 1px 10px rgba(0,0,0,0.3)',
  },
  backupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  auto_align: {
    margin: 'auto',
  },
  right_align: {
    margin: 'auto 0 auto auto',
  },
  fullWidth: {
    width: '90%',
    marginRight: 10,
  },
  checkboxField: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    display: 'block',
    marginLeft: 10,
    cursor: 'pointer',
  },
});

const EditDetails = (props) => {
  const classes = useStyles();
  const [updateSiteFields] = useMutation(EditSite);
  const [updateSiteName] = useMutation(UpdateSiteName);
  const [updateDefaultPricingStrategy] = useMutation(UpdateDefaultPricingStrategy);
  const [updateSiteAddress] = useMutation(UpdateSiteAddress);
  const [updateImportEmailRecipients] = useMutation(UpdateImportEmailRecipients);
  const [updateAwardEmailTemplate] = useMutation(UpdateAwardEmailTemplate);
  const [updateSiteTags] = useMutation(UpdateSiteTags);
  const [newItemEmail, setNewItemEmail] = useState();
  const [newInventoryEmail, setNewInventoryEmail] = useState();
  const [charityName, setCharityName] = useState();

  const {
    fiscalYearStart,
    defaultPricingPercentage,
    selectedAnchor,
    siteName,
    siteId,
    editedSiteName,
    tags,
    street,
    city,
    stateName,
    zip,
    countryCode,
    itemImportEmailRecipients,
    inventoryImportEmailRecipients,
    charities,
    siteRole,
    siteNumber,
    archived,
    archivedReason,
    setSiteName,
    setFiscalYearStart,
    setDefaultPricingStrategy,
    setDefaultPricingPercentage,
    setEditedSiteName,
    setTags,
    setStreet,
    setCity,
    setStateName,
    setZip,
    setCountryCode,
    setItemImportEmailRecipients,
    setInventoryImportEmailRecipients,
    setCharities,
    awardEmailTemplate,
    setAwardEmailTemplate,
    setSiteNumber,
    setArchived,
    setArchivedReason,
  } = props;

  const successMessage = (message) => {
    AlertService.alert({
      type: 'success',
      autoDismiss: true,
      dismissDelay: 3000,
      message: <span>{message} successfully updated.</span>,
    });
  };

  const errorMessage = (error) => {
    AlertService.alert({ type: 'warning', autoDismiss: true, dismissDelay: 3000, message: <span>Error: {error.message}</span> });
  };

  const saveFiscalYear = async (e) => {
    e.preventDefault();
    try {
      if (fiscalYearStart > 12 || fiscalYearStart < 1) throw new Error('Fiscal year start month should be a number between 1 and 12');
      const site = {
        siteName,
        fiscalYearStart: Number(fiscalYearStart),
      };
      await updateSiteFields({
        variables: { site },
      });
      successMessage('Fiscal year start month');
    } catch (error) {
      errorMessage(error);
    }
  };

  const savePricingStrategy = async () => {
    try {
      await updateDefaultPricingStrategy({
        variables: { siteId, defaultPricingStrategy: selectedAnchor.value, defaultPricingPercentage: Number(defaultPricingPercentage) },
      });
      successMessage('Default pricing strategy');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveEditName = async () => {
    try {
      await updateSiteName({
        variables: { siteName, editedSiteName },
      });
      setSiteName(editedSiteName);
      successMessage('Site name');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveSiteAddress = async () => {
    try {
      if (!(street && city && zip && countryCode)) throw new Error('New site address must have all of its fields filled out except state');
      await updateSiteAddress({
        variables: {
          siteId,
          siteAddress: {
            city,
            street,
            stateName: stateName.value,
            zip,
            countryCode,
          },
        },
      });
      successMessage('Site address');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveItemEmailAddress = async () => {
    try {
      await updateImportEmailRecipients({
        variables: {
          siteId,
          importEmailRecipients: itemImportEmailRecipients,
          emailType: 'itemImportEmailRecipients',
        },
      });
      successMessage('Updated item import email recipients');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveInventoryEmailAddress = async () => {
    try {
      await updateImportEmailRecipients({
        variables: {
          siteId,
          importEmailRecipients: inventoryImportEmailRecipients,
          emailType: 'inventoryImportEmailRecipients',
        },
      });
      successMessage('Updated inventory import email recipients');
    } catch (error) {
      errorMessage(error);
    }
  };

  const addItemEmail = () => {
    setItemImportEmailRecipients([...itemImportEmailRecipients, newItemEmail]);
    setNewItemEmail('');
  };

  const addInventoryEmail = () => {
    setInventoryImportEmailRecipients([...inventoryImportEmailRecipients, newInventoryEmail]);
    setNewInventoryEmail('');
  };

  const removeItemEmail = (email) => {
    const index = itemImportEmailRecipients.findIndex((itemEmail) => itemEmail === email);
    const itemImportEmailRecipientsCopy = [...itemImportEmailRecipients];
    itemImportEmailRecipientsCopy.splice(index, 1);
    setItemImportEmailRecipients(itemImportEmailRecipientsCopy);
  };

  const removeInventoryEmail = (email) => {
    const index = inventoryImportEmailRecipients.findIndex((inventoryEmail) => inventoryEmail === email);
    const inventoryImportEmailRecipientsCopy = [...inventoryImportEmailRecipients];
    inventoryImportEmailRecipientsCopy.splice(index, 1);
    setInventoryImportEmailRecipients(inventoryImportEmailRecipientsCopy);
  };

  const saveCharity = async () => {
    try {
      if (!charityName) throw new Error('Nonprofit site name is required');
      const site = {
        siteName,
        charityName,
      };
      await updateSiteFields({
        variables: { site },
      });
      setCharities([...charities, { siteId: '', siteName: charityName }]);
      setCharityName('');
      successMessage('Charity');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveAwardSheetEmailTemplate = async () => {
    try {
      await updateAwardEmailTemplate({
        variables: { siteId, emailTemplateId: awardEmailTemplate },
      });
      successMessage('Award Sheet Email Template');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveTags = async () => {
    try {
      await updateSiteTags({
        variables: { siteId, tags: tags.map((t) => t.value) },
      });
      successMessage('Site tags have been updated');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveSiteNumber = async (e) => {
    e.preventDefault();
    try {
      const site = {
        siteName,
        itemImportSite: siteNumber,
      };
      await updateSiteFields({
        variables: { site },
      });
      successMessage('Site number');
    } catch (error) {
      errorMessage(error);
    }
  };

  const saveArchived = async () => {
    try {
      await updateSiteFields({
        variables: { site: { siteName, archived, archivedReason } },
      });
      successMessage('Site archived status');
    } catch (error) {
      errorMessage(error);
    }
  };

  const itemEmail = (
    <>
      <div className={classes.dataUtility}>
        <div className={classes.dataUtilityInner}>
          <h4>Item Import Email Recipients</h4>
          <div>
            {itemImportEmailRecipients.map((email, index) => (
              <div key={`.item-email-${index}`} className={classes.rowInputContainer}>
                <Pill fileName={email} onCancel={removeItemEmail} />
              </div>
            ))}
            <div className={classes.rowInputContainer}>
              <TextInput value={newItemEmail} onChange={setNewItemEmail} type="email" labelText="Email" required className={classes.fullWidth} />
              <Button type="button" className={classes.auto_align} onClick={addItemEmail}>
                +
              </Button>
            </div>
            <Button type="button" className={classes.button__prev} onClick={saveItemEmailAddress}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  const inventoryEmail = (
    <>
      <div className={classes.dataUtility}>
        <div className={classes.dataUtilityInner}>
          <h4>Inventory Import Email Recipients</h4>
          <div>
            {inventoryImportEmailRecipients.map((email, index) => (
              <div key={`.inventory-email-${index}`} className={classes.rowInputContainer}>
                <Pill fileName={email} onCancel={removeInventoryEmail} />
              </div>
            ))}
            <div className={classes.rowInputContainer}>
              <TextInput
                value={newInventoryEmail}
                onChange={setNewInventoryEmail}
                type="email"
                labelText="Email"
                required
                className={classes.fullWidth}
              />
              <Button type="button" className={classes.auto_align} onClick={addInventoryEmail}>
                +
              </Button>
            </div>
            <Button type="button" className={classes.button__prev} onClick={saveInventoryEmailAddress}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  const charitiesList = (
    <>
      <div className={classes.dataUtility}>
        <div className={classes.dataUtilityInner}>
          <h4>Charities</h4>
          <ul>
            {charities.map((charity) => (
              <li key={charity.siteName}>{charity.siteName}</li>
            ))}
          </ul>
          <h4>Add new nonprofit site</h4>
          <TextInput value={charityName} type="text" labelText="Nonprofit Site Name" onChange={setCharityName} required />
          <Button type="button" className={classes.button__prev} onClick={saveCharity}>
            Save
          </Button>
        </div>
      </div>
    </>
  );

  const cityInput = (
    <>
      <div className={classes.dataUtility}>
        <div className={classes.dataUtilityInner}>
          <h4>Location Info</h4>
          <div className={classes.rowInputContainer}>
            <div className={classes.address}>
              <TextInput onChange={setStreet} value={street} type="text" labelText="Street Address" required />
              <TextInput className={classes.genericInput} onChange={setCity} value={city} type="text" labelText="City" required />
              <Select
                onChange={(stateNameValue) => setStateName(stateNameValue[0])}
                required
                label="State"
                selectedItem={stateName}
                containerClassName={classes.genericInput}
              >
                {listOfStates.map((region) => {
                  return (
                    <SelectOption key={region.id} value={region.id}>
                      {region.text}
                    </SelectOption>
                  );
                })}
              </Select>
              <TextInput className={classes.genericInput} onChange={setZip} value={zip} type="text" labelText="Zip" required />
              <TextInput
                className={classes.genericInput}
                onChange={setCountryCode}
                value={countryCode}
                type="text"
                labelText="Country Code"
                required
              />
            </div>
          </div>
          <Button type="button" className={classes.button__prev} onClick={saveSiteAddress}>
            Save
          </Button>
        </div>
      </div>
    </>
  );

  const archivedInput = (
    <div className={classes.rowInputContainer}>
      <div className={classes.checkboxField}>
        <Checkbox value="archived" checked={archived} onChecked={({ checked }) => setArchived(checked)} />
        <span className={classes.checkboxLabel} onClick={() => setArchived(!archived)}>
          Archived
        </span>
      </div>
      <TextInput className={classes.fullWidth} onChange={setArchivedReason} type="text" labelText="Reason for Archiving" value={archivedReason} />
      <Button type="button" className={classes.right_align} onClick={saveArchived}>
        Save
      </Button>
    </div>
  );

  const miscInfo = (
    <>
      <div className={classes.dataUtility}>
        <div className={classes.dataUtilityInner}>
          <h4>Misc Info</h4>
          <div>
            <div className={classes.rowInputContainer}>
              <TextInput
                className={classes.genericInput}
                onChange={(startMonth) => setFiscalYearStart(Number(startMonth))}
                value={fiscalYearStart}
                min={1}
                max={12}
                type="number"
                labelText="fiscalYearStart *"
                required
              />
              <Button type="button" onClick={saveFiscalYear} className={classes.right_align}>
                Save
              </Button>
            </div>
            <div className={classes.rowInputContainer}>
              <Select
                label="Default Anchor"
                onChange={(anchor) => setDefaultPricingStrategy(anchor[0])}
                selectedItem={selectedAnchor}
                containerClassName={classes.genericInput}
              >
                {Array.from(pricingStrategyAnchor.keys()).map((k) => (
                  <SelectOption key={k} value={k}>
                    {pricingStrategyAnchor.get(k)}
                  </SelectOption>
                ))}
              </Select>
              <TextInput
                type="number"
                min={0}
                step={0.01}
                value={defaultPricingPercentage}
                onChange={(percent) => setDefaultPricingPercentage(accounting.unformat(percent))}
                labelText="Default Anchor Modifier"
                className={classes.genericInput}
              />
              <Button type="button" className={classes.right_align} onClick={savePricingStrategy}>
                Save
              </Button>
            </div>
            <div className={classes.rowInputContainer}>
              <TextInput type="text" value={siteName} onChange={setEditedSiteName} labelText="Edit Site Name" className={classes.genericInput} />
              <Button type="button" className={classes.right_align} onClick={saveEditName}>
                Save
              </Button>
            </div>
            <div className={classes.rowInputContainer}>
              <TextInput
                type="text"
                value={awardEmailTemplate}
                onChange={setAwardEmailTemplate}
                labelText="Edit Award Sheet Email Template"
                className={classes.fullWidth}
              />
              <Button type="button" className={classes.right_align} onClick={saveAwardSheetEmailTemplate}>
                Save
              </Button>
            </div>
            <div className={classes.rowInputContainer}>
              <Select autoWidth multiple onChange={setTags} label="Tags" selectedItems={[...tags]} containerClassName={classes.genericInput}>
                {siteTags.map((tag) => {
                  return (
                    <SelectOption checkbox key={tag} value={tag} selected={tags.findIndex((t) => t.value === tag) > -1}>
                      {tag}
                    </SelectOption>
                  );
                })}
              </Select>
              <Button type="button" className={classes.right_align} onClick={saveTags}>
                Save
              </Button>
            </div>
            <div className={classes.rowInputContainer}>
              <TextInput type="text" value={siteNumber} onChange={setSiteNumber} labelText="Site Number" className={classes.genericInput} />
              <Button type="button" className={classes.right_align} onClick={saveSiteNumber}>
                Save
              </Button>
            </div>
            {archivedInput}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div className={classes.dataUtilitiesGrid}>
        {miscInfo}
        {cityInput}
        {siteRole === 'SELLER' && itemEmail}
        {siteRole === 'SELLER' && inventoryEmail}
        {siteRole === 'DONATION SELLER' && charitiesList}
      </div>
    </div>
  );
};

EditDetails.propTypes = {
  props: PropTypes.object,
  classes: PropTypes.object,
  fiscalYearStart: PropTypes.number,
  siteName: PropTypes.string,
  defaultPricingPercentage: PropTypes.number,
  selectedAnchor: PropTypes.object,
  siteId: PropTypes.string,
  editedSiteName: PropTypes.string,
  tags: PropTypes.array,
  setStreet: PropTypes.func,
  setCity: PropTypes.func,
  setStateName: PropTypes.func,
  setZip: PropTypes.func,
  setCountryCode: PropTypes.func,
  setTags: PropTypes.func,
  setEditedSiteName: PropTypes.func,
  setSiteName: PropTypes.func,
  setDefaultPricingPercentage: PropTypes.func,
  setDefaultPricingStrategy: PropTypes.func,
  setFiscalYearStart: PropTypes.func,
  setItemImportEmailRecipients: PropTypes.func,
  setInventoryImportEmailRecipients: PropTypes.func,
  setCharities: PropTypes.func,
  street: PropTypes.string,
  city: PropTypes.string,
  zip: PropTypes.string,
  countryCode: PropTypes.string,
  stateName: PropTypes.object,
  itemImportEmailRecipients: PropTypes.array,
  inventoryImportEmailRecipients: PropTypes.array,
  siteRole: PropTypes.string,
  charities: PropTypes.array,
  awardEmailTemplate: PropTypes.string,
  setAwardEmailTemplate: PropTypes.func,
  siteNumber: PropTypes.string,
  setSiteNumber: PropTypes.func,
  archived: PropTypes.bool,
  setArchived: PropTypes.func,
  archivedReason: PropTypes.string,
  setArchivedReason: PropTypes.func,
};

export default EditDetails;

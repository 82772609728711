import React from 'react';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TextInput, AlertService, Modal, ModalContent, ModalFooter, Button, Theme } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { SaveOffersMutation } from './offer-manager-mutations';

const useStyles = createUseStyles({
  modalContent: {
    width: 652,
  },
  inventoryFields: {
    maxHeight: 400,
    overflowY: 'auto',
    border: `1px solid ${Theme.grey30}`,
    padding: '10px 10px 0',
  },
  box: {
    marginBottom: 10,
  },
  fieldBox: {
    extend: 'box',
  },
  fieldLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.3)',
    userSelect: 'none',
    display: 'block',
  },
  info: {
    listStyle: 'outside',
    paddingLeft: '2em',
    color: 'rgba(0, 0, 0, 0.3)',
    margin: '0 0 20px',
    '& li': {
      fontSize: 12,
      color: 'rgba(0, 0, 0, 0.3)',
    },
  },
});
const OfferEditor = ({ offer, setOffer, handleHideModal }) => {
  const classes = useStyles();
  const [saveOffers, { loading: saving }] = useMutation(SaveOffersMutation);
  const handleSave = () => {
    const variables = {
      offers: [
        {
          _id: offer._id,
          quantity: offer.quantity,
          totalPrice: offer.totalPrice,
          buyerProposedQuantity: parseInt(offer.buyerProposedQuantity, 10),
          buyerProposedTotalPrice: offer.buyerProposedTotalPrice,
        },
      ],
    };
    saveOffers({ variables })
      .then((response) => {
        if (response.data.saveOffers.errors?.length > 0) {
          throw new Error(response.data.saveOffers.errors[0].message);
        }
        setOffer(null);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };
  const updateValue = (field, value) => {
    const _offer = { ...offer };
    _offer[field] = value;
    setOffer(_offer);
  };
  return (
    <Modal onHide={handleHideModal} open={!!offer} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <div className={classes.modalContent}>
          <h2>Edit Offer</h2>
          {offer?._id && (
            <div className={classes.inventoryFields}>
              <ul className={classes.info}>
                <li>Offer ID: {offer._id}</li>
                <li>Logistics relationship: {offer.logisticsRelationshipName}</li>
                <li>Quantity: {offer.quantity}</li>
                <li>Total Price: {offer.totalPrice}</li>
              </ul>
              <div className={classes.fieldBox}>
                <TextInput
                  className={classes.textField}
                  onChange={updateValue.bind(this, 'buyerProposedQuantity')}
                  value={offer.buyerProposedQuantity || ''}
                  type="number"
                  min={0}
                  step={0.01}
                  labelText="Buyer Proposed Quantity"
                  required
                />
              </div>
              <div className={classes.fieldBox}>
                <TextInput
                  className={classes.textField}
                  onChange={updateValue.bind(this, 'buyerProposedTotalPrice')}
                  value={offer.buyerProposedTotalPrice || ''}
                  type="number"
                  min={0}
                  step={0.01}
                  labelText="Buyer Proposed Total Price"
                  required
                />
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={handleHideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={saving} primary loading={saving} loadingText="Saving..." onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
OfferEditor.propTypes = {
  offer: PropTypes.object,
  setOffer: PropTypes.func,
  handleHideModal: PropTypes.func,
};
export default OfferEditor;

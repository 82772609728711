import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import {
  Column,
  DataTableNaked,
  Theme,
  Button,
  Select,
  SelectOption,
  RowAction,
  PencilIcon,
  MiniTooltip,
  AlertService,
  ColorBadge,
  CloseIcon,
} from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import { AdminDashboardListQuery, AdminDashboardRenderQuery, AdminDashboardDrillthroughsQuery } from '../../graphql/queries';
import { DeleteHolisticsDashboardMutation, SaveHolisticsDashboardMutation } from '../../graphql/mutations';
import { Preview, Trash, Refresh } from '../../icons';
import EditDashboardModal from './edit-dashboard-modal';

const useStyles = createUseStyles({
  tableWrap: {
    border: `1px solid ${Theme.tableBorderColor}`,
    marginBottom: 15,
    position: 'relative',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  controlBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  siteSelect: {
    width: 300,
  },
  reportName: {
    color: Theme.green,
    fontWeight: 500,
  },
  smallerText: {
    fontSize: '0.9em',
  },
  sellerSites: {
    marginTop: 7,
  },
  dataset: {
    color: Theme.redVeryDark,
  },
  pillList: {
    '&>div': {
      margin: [7, 0],
    },
  },
  viewerOverlay: {
    position: 'fixed',
    height: '100vh',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0,0,0,0.5)',
    zIndex: 100,
  },
  viewer: {
    position: 'relative',
    margin: 40,
    height: 'calc(100% - 80px)',
    background: Theme.white,
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    padding: 20,
  },
  viewerInner: {
    height: '100%',
    border: `2px solid ${Theme.grey50}`,
  },
  iframe: {
    width: '100%',
    height: '100%',
  },
  closeViewer: {
    position: 'absolute',
    top: -10,
    right: -10,
    background: Theme.white,
    borderRadius: 20,
    width: 40,
    height: 40,
    boxShadow: '0 0 5px rgba(0,0,0,0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      background: Theme.grey30,
    },
    '&>svg': {
      fill: Theme.greyDark,
    },
  },
});

const reportTypeBadgeColors = {
  CORE: Theme.badgeColors.blue,
  CUSTOM: Theme.badgeColors.purple,
};

const permissionFieldAbbreviations = {
  site_org_id: 'Org ID',
  site_id: 'Cycle Site ID',
  seller_org_site_id: 'Seller Org ID',
  _id: 'Seller Org ID',
};

const DashboardManager = () => {
  const classes = useStyles();
  const { data, loading: loadingDashboards } = useQuery(AdminDashboardListQuery);

  const [siteSelection, setSiteSelection] = useState(null);
  const [viewing, setViewing] = useState(null);
  const [editingDashboard, setEditingDashboard] = useState(null);

  const dashboards = data?.adminDashboardListQuery?.dashboards || [];

  const { data: embedResult, loading: loadingEmbed } = useQuery(AdminDashboardRenderQuery, {
    variables: {
      dashboardIds: dashboards.map((d) => d._id),
      sellerSiteId: siteSelection?.length > 0 ? siteSelection[0].value : null,
    },
    skip: !dashboards.length || !siteSelection,
  });

  const { data: drillThroughDashboards, loading: loadingDrills } = useQuery(AdminDashboardDrillthroughsQuery, {
    variables: {
      dashboardId: parseFloat(editingDashboard?.dashboardId || 0),
    },
    skip: !editingDashboard?.dashboardId,
  });

  const drillThruOptions = drillThroughDashboards?.adminDashboardDrillthroughsQuery || [];

  const [saveDashboardToDb, { loading: creating }] = useMutation(SaveHolisticsDashboardMutation);
  const [deleteDashboardFromDb, { loading: deleting }] = useMutation(DeleteHolisticsDashboardMutation);

  const loading = loadingDashboards || loadingEmbed || creating || deleting;

  const refreshDashboard = (row) => {
    const variables = {
      _id: row._id,
      dashboardType: row.type,
      refreshDatasets: true,
    };
    saveDashboardToDb({ variables }).catch((error) =>
      AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 })
    );
  };

  const saveDashboard = () => {
    const variables = {
      dashboardType: editingDashboard.dashboardType[0].value,
      defaultForLocation: editingDashboard.defaultForLocation?.length > 0 ? editingDashboard.defaultForLocation[0].value : undefined,
      sellerSiteIds: editingDashboard.sellerSiteIds ? editingDashboard.sellerSiteIds.map((ss) => ss.value) : [],
      drillthroughs: editingDashboard.drillthroughs?.map((d) => d.value),
      refreshDatasets: false,
    };
    if (editingDashboard._id) {
      variables._id = editingDashboard._id;
      variables.name = editingDashboard.name;
    } else {
      variables.dashboardId = parseFloat(editingDashboard.dashboardId);
    }
    saveDashboardToDb({ variables, refetchQueries: ['adminDashboardListQuery'] })
      .then((response) => {
        if (response.data.saveHolisticsDashboard.errors?.length > 0) {
          throw new Error(response.data.saveHolisticsDashboard.errors[0].message);
        }
        setEditingDashboard(null);
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
  };

  useEffect(() => {
    TitleService.setTitles('Administration Panel');
    Breadcrumbs.set([
      {
        url: '/',
        title: 'Holistics Dashboards',
      },
    ]);
  }, []);

  const filteredDashboards = useMemo(() => {
    if (siteSelection?.length > 0) {
      const siteId = siteSelection[0].value;
      return dashboards.filter((dash) => dash.type === 'CORE' || dash.sellerSiteIds.includes(siteId));
    }
    return [...dashboards];
  }, [dashboards, siteSelection]);

  const sites = data?.adminDashboardListQuery?.sellerOrgs || [];

  const sitesMap = useMemo(() => {
    return sites.reduce((map, site) => {
      map.set(site._id, site);
      return map;
    }, new Map());
  }, [sites]);

  const permissionPathFormatter = (path) => {
    const [dataset, , field] = path.split('.');
    return (
      <ColorBadge theme={Theme.badgeColors.yellow}>
        <span className={classes.dataset}>
          {dataset} {'->'}
        </span>{' '}
        {permissionFieldAbbreviations[field]}
      </ColorBadge>
    );
  };

  const columns = useMemo(() => {
    return [
      new Column({
        field: 'name',
        displayName: 'Report Name & ID',
        sortable: true,
        formatter: (value, row) => (
          <>
            <span className={classes.reportName}>{row.name}</span>
            <br />
            <span title="Holistics Report ID">{row.reportId}</span>
            <br />
            <span title="Spoiler Alert Report ID">{row.uname}</span>
          </>
        ),
      }),
      new Column({
        field: 'type',
        displayName: 'Type',
        sortable: true,
        formatter: (value, row) => (
          <>
            <ColorBadge theme={reportTypeBadgeColors[value]}>{value}</ColorBadge>
            {value === 'CUSTOM' && (
              <div className={classes.sellerSites}>
                {row.sellerSiteIds.map((v) => (
                  <ColorBadge key={v} theme={Theme.badgeColors.pink}>
                    {sitesMap.get(v).siteName}
                  </ColorBadge>
                ))}
              </div>
            )}
          </>
        ),
      }),
      new Column({
        field: 'defaultForLocation',
        displayName: 'Location Default?',
        sortable: true,
        formatter: (value) => (value ? <ColorBadge theme={Theme.badgeColors.green}>{value}</ColorBadge> : ''),
      }),
      new Column({
        field: 'orgFilterPaths',
        displayName: 'Permissions Paths',
        formatter: (values) => (
          <div className={classes.pillList}>
            {values.map((v) => (
              <div key={v}>
                <MiniTooltip text={v}>{permissionPathFormatter(v)}</MiniTooltip>
              </div>
            ))}
          </div>
        ),
      }),
      new Column({
        field: 'drillthroughs',
        displayName: 'Drills to...',
        formatter: (values) => <div className={classes.smallerText}>{values.join(', ')}</div>,
      }),
      new Column({
        field: 'updatedAt',
        displayName: 'Last Changed',
        formatter: (value) => moment(value).fromNow(),
        defaultSort: true,
        defaultSortDirection: 'desc',
        sortable: true,
      }),
    ];
  }, [sitesMap]);

  const handleChangeSite = (option) => {
    setSiteSelection(option);
  };

  const handleViewAs = (row) => setViewing(row._id);

  const iframeUrl = useMemo(() => {
    if (viewing && embedResult) {
      const params = embedResult.adminDashboardRenderQuery.find((r) => r._id === viewing);
      return params.url;
    }
    return '';
  }, [viewing, siteSelection, embedResult]);

  const handleHideModal = () => setEditingDashboard(null);

  const updateValue = (field, value) => {
    const _dashboard = { ...editingDashboard };
    _dashboard[field] = value;
    setEditingDashboard(_dashboard);
  };

  const handleEdit = (row) => {
    const { type, sellerSiteIds, defaultForLocation, name, reportId, drillthroughs } = row;
    const dashboard = {
      _id: row._id,
      name,
      dashboardId: reportId,
      dashboardType: [{ text: type, value: type }],
      defaultForLocation: defaultForLocation ? [{ text: defaultForLocation, value: defaultForLocation }] : [{ text: 'None', value: '' }],
      sellerSiteIds: sellerSiteIds.map((ssid) => ({ text: sitesMap.get(ssid).siteName, value: ssid })),
      drillthroughs: drillthroughs.map((drill) => ({ text: 'Loading...', value: drill })),
    };
    setEditingDashboard(dashboard);
  };

  const handleNew = () => {
    setEditingDashboard({
      dashboardId: '',
      dashboardType: [],
      defaultForLocation: [{ text: 'None', value: '' }],
      sellerSiteIds: [],
    });
  };

  const handleDelete = async (row) => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to delete the Dashboard ${row.name}?`)) {
      await deleteDashboardFromDb({
        variables: {
          _id: row._id,
        },
        refetchQueries: ['adminDashboardListQuery'],
      });
    }
  };

  useEffect(() => {
    if (editingDashboard?.drillthroughs?.length > 0 && drillThruOptions?.length > 0 && editingDashboard.drillthroughs[0].text === 'Loading...') {
      const newDTValues = editingDashboard.drillthroughs
        .map((dt) => {
          const loadedOption = drillThruOptions.find((dto) => dto.dashboardId === dt.value);
          if (!loadedOption) return null;
          return {
            value: dt.value,
            text: loadedOption.name,
          };
        })
        .filter((dt) => dt !== null);
      updateValue('drillthroughs', newDTValues);
    }
  }, [editingDashboard, drillThruOptions]);

  const siteSelectOptions = useMemo(() => {
    if (sites) {
      return sites.map((s) => (
        <SelectOption key={s._id} value={s._id} searchText={s.siteName}>
          {s.siteName}
        </SelectOption>
      ));
    }
    return [];
  }, [sites]);

  return (
    <div>
      <div className={classes.controlBar}>
        <div className={classes.siteSelect}>
          <Select search label="Preview & Filter for..." onChange={handleChangeSite} selectedItems={siteSelection}>
            {siteSelectOptions}
          </Select>
        </div>
        <div>
          <Button onClick={handleNew}>Create New</Button>
        </div>
      </div>
      <div className={classes.tableWrap}>
        <DataTableNaked
          data={filteredDashboards}
          columns={columns}
          loading={loading}
          rowActions={[
            <RowAction
              key={0}
              tooltipText={siteSelection?.length > 0 ? `View as ${siteSelection[0].text}` : 'Select a Site'}
              icon={Preview}
              onClick={(row) => handleViewAs.bind(this, row)}
              primary
              disabled={!siteSelection?.length > 0}
            />,
            <RowAction
              key={1}
              tooltipText="Sync Permissions with Holistics"
              icon={Refresh}
              onClick={(row) => refreshDashboard.bind(this, row)}
              primary
            />,
            <RowAction key={2} tooltipText="Edit" icon={PencilIcon} onClick={(row) => handleEdit.bind(this, row)} secondary />,
            <RowAction key={3} tooltipText="Delete" icon={Trash} onClick={(row) => handleDelete.bind(this, row)} warning />,
          ]}
        />
      </div>
      {viewing && iframeUrl !== '' && (
        <div className={classes.viewerOverlay} onClick={() => setViewing(null)}>
          <div className={classes.viewer} onClick={(e) => e.stopPropagation()}>
            <a className={classes.closeViewer} onClick={() => setViewing(null)}>
              <CloseIcon />
            </a>
            <div className={classes.viewerInner}>
              <iframe
                src={iframeUrl}
                id="embedded-iframe"
                className={classes.iframe}
                frameBorder="0"
                allowFullScreen
                allow="clipboard-read; clipboard-write"
              ></iframe>
            </div>
          </div>
        </div>
      )}
      <EditDashboardModal
        editingDashboard={editingDashboard}
        handleHideModal={handleHideModal}
        saveDashboard={saveDashboard}
        loadingDrills={loadingDrills}
        creating={creating}
        updateValue={updateValue}
        drillThruOptions={drillThruOptions}
        siteSelectOptions={siteSelectOptions}
      />
    </div>
  );
};

DashboardManager.propTypes = {
  user: PropTypes.object,
};

export default DashboardManager;

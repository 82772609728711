import React, { useEffect, useState, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { PropTypes } from 'prop-types';
import injectSheet from 'react-jss';
import { Loading, Button, AlertService } from '@spoiler-alert/ui-library';
import { TitleService } from '../../services';
import { Breadcrumbs } from '../../store';
import ExportManagerNavigation from './em-navigation';
import NewExportProfile from './new-export-profile';
import EditExportProfileModal from './edit-export-profile-modal';
import ExportMappingsTable from './export-mappings-table';
import MultilineHeadersTable from './multiline-headers-table';
import {
  EXPORT_PROFILE_FIELDS_QUERY,
  CREATE_OFFER_EXPORT_PROFILE_MUTATION,
  CREATE_AWARD_EXPORT_PROFILE_MUTATION,
  CREATE_MARKETPLACE_AUCTION_EXPORT_PROFILE_MUTATION,
  CREATE_EXPORT_MAPPING_MUTATION,
  UPDATE_EXPORT_PROFILE_MUTATION,
  UPDATE_EXPORT_MAPPING_MUTATION,
  UPDATE_EXPORT_MAPPINGS_MUTATION,
  UPDATE_MAPPINGS_ORDER_MUTATION,
  DELETE_EXPORT_MAPPING_MUTATION,
} from './export-queries';
import ETLErrors from '../import-manager/etl-errors';
import ConfirmationModal from '../../components/modals/profile-upload-confirmation-modal';
import AppSettings from '../../app-settings';

const styles = {
  wrap: {
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 25,
    alignItems: 'center',
  },
  tableName: {
    width: '100%',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '5px',
  },
  pageButtons: {
    display: 'flex',
    flexDirection: 'row',
    '&>button': {
      marginLeft: 10,
    },
  },
};

const allowedFileTypes = ['application/json'];

const ExportManager = ({ classes }) => {
  const [exportType, setExportType] = useState('OFFER SHEET');
  const [site, setSite] = useState({ _id: null, siteName: null });
  const [exportProfile, setExportProfile] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [sheetWriters, setSheetWriters] = useState([]);
  const [confirmUpload, setConfirmUpload] = useState(false);
  const [fileInput, setFileInput] = useState();
  const [uploadKey, setUploadKey] = useState(0);

  useEffect(() => {
    TitleService.setTitles('ExportManager');
    Breadcrumbs.set([
      {
        url: '/export-manager',
        title: 'Export Manager',
      },
    ]);
  });

  const {
    data: profileFields,
    loading: loadingFields,
    error: loadFieldsError,
  } = useQuery(EXPORT_PROFILE_FIELDS_QUERY, {
    variables: {
      exportProfileId: exportProfile?._id,
    },
    skip: !exportProfile,
  });

  const getPossibleColors = () =>
    profileFields?.exportManagerQuery?.exportProfile?.possibleColors?.map((color) => ({
      name: color.name.replace(/\w+/g, (name) => name.charAt(0).toUpperCase() + name.slice(1)),
      hex: color.hex,
    }));

  const possibleColors = useMemo(() => getPossibleColors(), [profileFields?.exportManagerQuery?.exportProfile?.possibleColors]);

  const [createOfferExportProfile, { loading: creatingOfferExportProfile, error: createOfferExportProfileError }] = useMutation(
    CREATE_OFFER_EXPORT_PROFILE_MUTATION,
    {
      onCompleted: (data) => {
        setExportProfile(data.createOfferExportProfile);
      },
      refetchQueries: ['getSitesAndExportProfiles'],
    }
  );
  const [createAwardExportProfile, { loading: creatingAwardExportProfile, error: createAwardExportProfileError }] = useMutation(
    CREATE_AWARD_EXPORT_PROFILE_MUTATION,
    {
      onCompleted: (data) => {
        setExportProfile(data.createAwardExportProfile);
      },
      refetchQueries: ['getSitesAndExportProfiles'],
    }
  );
  const [
    createMarketplaceAuctionExportProfile,
    { loading: creatingMarketplaceAuctionExportProfile, error: createMarketplaceAuctionExportProfileError },
  ] = useMutation(CREATE_MARKETPLACE_AUCTION_EXPORT_PROFILE_MUTATION, {
    onCompleted: (data) => {
      setExportProfile(data.createMarketplaceAuctionExportProfile);
    },
    refetchQueries: ['getSitesAndExportProfiles'],
  });
  const [createExportMapping, { loading: creatingMapping, error: createMappingError }] = useMutation(CREATE_EXPORT_MAPPING_MUTATION);
  const [updateExportMapping, { data: updatedMapping, loading: savingMapping, error: saveMappingError }] =
    useMutation(UPDATE_EXPORT_MAPPING_MUTATION);
  const [updateMappingsOrder, { loading: savingMappingsOrder, error: updateMappingsOrderError }] = useMutation(UPDATE_MAPPINGS_ORDER_MUTATION);
  const [deleteExportMapping, { loading: deletingMapping, error: deleteMappingError }] = useMutation(DELETE_EXPORT_MAPPING_MUTATION);
  const [updateExportProfile, { loading: savingMultilineHeader, error: saveProfileError }] = useMutation(UPDATE_EXPORT_PROFILE_MUTATION, {
    onCompleted: (data) => {
      setExportProfile(data.updateExportProfile);
    },
    refetchQueries: ['getSitesAndExportProfiles'],
  });
  const [updateExportMappings, { loading: savingMappings, error: saveMappingsError }] = useMutation(UPDATE_EXPORT_MAPPINGS_MUTATION, {
    refetchQueries: ['getSitesAndExportProfiles', 'getFieldsByExportProfileId'],
  });

  const profileMutations = {
    createOfferExportProfile,
    createAwardExportProfile,
    createMarketplaceAuctionExportProfile,
  };

  const mappingMutations = {
    createExportMapping,
    updateExportMapping,
    deleteExportMapping,
    updateMappingsOrder,
  };

  const multilineHeaderMutations = {
    updateExportProfile,
  };

  const errors = [
    loadFieldsError,
    createOfferExportProfileError,
    createAwardExportProfileError,
    createMarketplaceAuctionExportProfileError,
    createMappingError,
    saveProfileError,
    saveMappingError,
    deleteMappingError,
    updateMappingsOrderError,
  ];

  const loading = () =>
    loadingFields || creatingOfferExportProfile || creatingAwardExportProfile || creatingMarketplaceAuctionExportProfile || savingMappings;

  const downloadExportProfile = () => {
    const cleanProfile = {
      _id: exportProfile._id,
      siteId: exportProfile.siteId,
      exportType: exportProfile.exportType,
      sheetWriterClass: exportProfile.sheetWriterClass,
      dateFormat: exportProfile.dateFormat,
      splitSheetsBySupplierSite: exportProfile.splitSheetsBySupplierSite,
      listName: exportProfile.listName,
      multilineHeaders: exportProfile.multilineHeaders.map((mh) => {
        return {
          label: mh.label,
          linkToStorefront: mh.linkToStorefront === null ? undefined : mh.linkToStorefront,
          headerType: mh.headerType,
          order: mh.order,
          merges: mh.merges === null ? undefined : mh.merges,
          columnStyles: {
            color: mh?.columnStyles?.color,
          },
          headerStyles: {
            color: mh?.headerStyles?.color,
          },
        };
      }),
    };
    const cleanMappings = profileFields.exportManagerQuery.exportProfile.mappings.map((mapping) => {
      return {
        _id: mapping._id,
        exportProfileId: mapping.exportProfileId,
        label: mapping.label,
        widthChars: mapping.widthChars,
        field: mapping.field === null ? undefined : mapping.field,
        formatter: mapping.formatter === null ? undefined : mapping.formatter,
        formatterArgs: mapping.formatterArgs === null ? undefined : mapping.formatterArgs,
        formula: mapping.formula === null ? undefined : mapping.formula,
        cellType: mapping.cellType === null ? undefined : mapping.cellType,
        hide: mapping.hide === null ? undefined : mapping.hide,
        order: mapping.order,
        locked: mapping.locked === null ? undefined : mapping.locked,
        columnStyles: {
          color: mapping?.columnStyles?.color,
        },
        headerStyles: {
          color: mapping?.headerStyles?.color,
        },
      };
    });
    const blob = new Blob([JSON.stringify({ exportProfile: cleanProfile, exportMappings: cleanMappings }, null, 2)], { type: 'application/json' });
    const link = document.createElement('a');
    link.download = `${AppSettings.ENVIRONMENT_NAME}_${site.siteName}_${exportProfile.exportType}_Export-Profile_${new Date()}.json`;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
  };

  const uploadExportProfile = () => {
    setConfirmUpload(true);
  };

  const showError = (errorMessage) => {
    AlertService.alert({ type: 'warning', message: <span>{errorMessage}</span>, autoDismiss: false });
  };

  const showSuccess = (successMessage) => {
    AlertService.alert({ type: 'success', message: <span>{successMessage}</span>, autoDismiss: true, dismissDelay: 3000 });
  };

  const handleFileChange = (ev) => {
    const fileList = ev.target.files || ev.dataTransfer.files;
    const files = Array.from(fileList);
    if (!files.every((file) => allowedFileTypes.includes(file.type))) {
      showError('Error: incorrect file type. Files must be in json format.');
    } else {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const profile = JSON.parse(e.target.result);
          updateExportProfile({
            variables: {
              exportProfile: profile.exportProfile,
            },
          });
          updateExportMappings({
            variables: {
              exportMappings: profile.exportMappings,
            },
          });
        } catch (error) {
          showError('Error: The file content is not valid JSON.');
        }
      };
      reader.onerror = () => {
        showError('Error: Failed to read the file.');
      };
      // Read the file as text (assuming JSON)
      reader.readAsText(file);
      // Rotating this key resets the file input element
      setUploadKey(uploadKey + 1);
      showSuccess('Export profile uploaded successfully');
    }
  };

  const renderView = () => {
    if (!site._id) {
      return <div></div>;
    }
    if (profileFields) {
      return (
        <>
          {(exportType === 'AWARD SHEET' || exportType === 'MARKETPLACE AUCTION SHEET') && (
            <>
              <span className={classes.tableName}>Multi-Line Headers</span>
              <MultilineHeadersTable
                exportProfile={exportProfile}
                mutations={multilineHeaderMutations}
                possibleColors={possibleColors}
                loading={savingMultilineHeader}
                site={site}
              />
            </>
          )}
          <span className={classes.tableName}>Columns</span>
          <ExportMappingsTable
            exportProfile={exportProfile}
            mutations={mappingMutations}
            mappings={profileFields.exportManagerQuery.exportProfile.mappings}
            possibleInternalFields={profileFields.exportManagerQuery.exportProfile.possibleInternalFields}
            possibleFormatters={
              exportType === 'OFFER SHEET' || exportType === 'MARKETPLACE AUCTION SHEET'
                ? profileFields.exportManagerQuery.exportProfile.possibleOfferExportFormatters
                : profileFields.exportManagerQuery.exportProfile.possibleAwardExportFormatters
            }
            possibleColors={possibleColors}
            updatedRecord={updatedMapping}
            loading={creatingMapping || savingMapping || deletingMapping || savingMappingsOrder}
          />
          <ConfirmationModal setConfirmUpload={setConfirmUpload} confirmUpload={confirmUpload} fileInput={fileInput} />
          <input
            type="file"
            id="sheet-upload"
            key={`upload-${uploadKey}`}
            onChange={handleFileChange}
            accept={allowedFileTypes.join(',')}
            style={{ display: 'none' }}
            ref={(el) => setFileInput(el)}
          />
        </>
      );
    }
    if (exportProfile === null) {
      return <NewExportProfile exportType={exportType} site={site} mutations={profileMutations} possibleSheetWriters={sheetWriters} />;
    }
    return <div></div>;
  };

  return (
    <div className={classes.wrap}>
      <header className={classes.header}>
        <ExportManagerNavigation
          site={site}
          setSite={setSite}
          setExportProfile={setExportProfile}
          exportType={exportType}
          setExportType={setExportType}
          setSheetWriters={setSheetWriters}
        />
        {exportProfile && (
          <div className={classes.pageButtons}>
            <Button primary onClick={uploadExportProfile} disabled={!!loadFieldsError || !!saveMappingsError || loading()}>
              Upload Profile
            </Button>
            <Button primary onClick={downloadExportProfile} disabled={!!loadFieldsError || !!saveMappingsError || loading()}>
              Download Profile
            </Button>
            <Button secondary onClick={() => setShowEditModal(true)}>
              Settings
            </Button>
          </div>
        )}
      </header>
      <ETLErrors errors={errors} />
      <Loading loading={loading()}>{renderView()}</Loading>
      {profileFields && (
        <EditExportProfileModal
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          exportProfile={exportProfile}
          updateExportProfile={updateExportProfile}
          possibleSheetWriters={sheetWriters}
        />
      )}
    </div>
  );
};

ExportManager.propTypes = {
  classes: PropTypes.object,
};

export default injectSheet(styles)(ExportManager);

import { gql } from '@apollo/client';

export const AdminOfferByCurrentCycleSiteQuery = gql`
  query adminOfferByCurrentCycleSiteQuery($siteId: String!) {
    adminOfferByCurrentCycleSiteQuery(siteId: $siteId) {
      _id
      buyerProposedQuantity
      buyerProposedTotalPrice
      inventoryId
      logisticsRelationshipName
      quantity
      totalPrice
    }
  }
`;

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import { TextInput, AlertService, Modal, ModalContent, ModalFooter, Button, Theme, Select, SelectOption } from '@spoiler-alert/ui-library';
import PropTypes from 'prop-types';
import { AddNUMOCOCompany } from '../../graphql/mutations';

const useStyles = createUseStyles({
  modalContent: {
    width: 652,
    display: 'flex',
    flexDirection: 'column',
  },
  box: {
    marginBottom: 10,
  },
  fieldBox: {
    extend: 'box',
    width: '100%',
  },
  fieldLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.3)',
    userSelect: 'none',
    display: 'block',
  },
  userBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    width: '100%',
  },
  companyBox: {
    margin: '12px 0',
    width: '50%',
  },
  removeContainer: {
    cursor: 'pointer',
    alignContent: 'center',
  },
  removeIcon: {
    height: 16,
    width: 16,
    fill: Theme.red,
    '&:hover': {
      fill: Theme.redVeryDark,
    },
  },
});

const NUMOCOCompanyModal = ({ buyerProfile, handleHideModal, open }) => {
  const classes = useStyles();
  const [entity, setEntity] = useState({});
  const [addNUMOCOCompany, { loading }] = useMutation(AddNUMOCOCompany);

  const handleAdd = async () => {
    await addNUMOCOCompany({ variables: { siteName: buyerProfile?.siteName, type: entity.type?.value, billingEmail: entity.billingEmail } })
      .then((response) => {
        if (response.data.addNUMOCOCompany.errors?.length > 0) {
          throw new Error(response.data.addNUMOCOCompany.errors[0].message);
        }
      })
      .catch((error) => AlertService.alert({ type: 'warning', message: <span>{error.message}</span>, autoDismiss: true, dismissDelay: 3000 }));
    handleHideModal();
  };

  const updateValue = (field, value) => {
    const _entity = { ...entity };
    _entity[field] = value;
    setEntity(_entity);
  };

  return (
    <Modal onHide={handleHideModal} open={open} closeOnEsc closeOnOutsideClick>
      <ModalContent>
        <div className={classes.modalContent}>
          <h2>Add Company</h2>
          <span>{buyerProfile?.siteName} does not yet exist in the NUMOCO system.</span>
          <div className={classes.companyBox}>
            <div className={classes.fieldBox}>
              <Select label="Entity Type" onChange={(value) => updateValue('type', value[0])} selectedItem={entity.type}>
                <SelectOption key={'BUSINESS'} value={'BUSINESS'} selected={entity?.type === 'BUSINESS'}>
                  BUSINESS
                </SelectOption>
                <SelectOption key={'INDIVIDUAL'} value={'INDIVIDUAL'} selected={entity?.type === 'INDIVIDUAL'}>
                  INDIVIDUAL
                </SelectOption>
              </Select>
            </div>
            <div className={classes.fieldBox}>
              <TextInput labelText="Billing Email" onChange={(value) => updateValue('billingEmail', value)} />
            </div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button type="button" onClick={handleHideModal} link>
          Cancel
        </Button>
        <Button type="submit" disabled={loading || !entity.type} primary loading={loading} loadingText="Addding Company..." onClick={handleAdd}>
          Add NUMOCO Company
        </Button>
      </ModalFooter>
    </Modal>
  );
};

NUMOCOCompanyModal.propTypes = {
  buyerProfile: PropTypes.object,
  handleHideModal: PropTypes.func,
  open: PropTypes.bool,
};

export default NUMOCOCompanyModal;
